// Base

export const SERVICES_BASE_URL = `${import.meta.env.VITE_SERVICES_BASE_URL}`;


// Extra
export const LOCATIONS_URL = "https://bj-decoupage-territorial.herokuapp.com/api/v1/departments/";
export const CITIES_URL = "https://countriesnow.space/api/v0.1/countries/cities";

// Keycloak
export const KEYCLOAK_BASE_URL = `${import.meta.env.VITE_KEYCLOAK_BASE_URL}`;
export const KEYCLOAK_AUTH_ACCESS_CLIENT_ID = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_CLIENT_ID}`;
export const KEYCLOAK_AUTH_ACCESS_GRANT_TYPE = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_GRANT_TYPE}`;
export const KEYCLOAK_AUTH_ACCESS_REALM = `${import.meta.env.VITE_KEYCLOAK_AUTH_ACCESS_REALM}`;


// Bifrost Bridge Service Endpoints
export const BIFROST_BRIDGE_BASE_URL = `${import.meta.env.VITE_BIFROST_BRIDGE_BASE_URL}`;
export const KEYCLOAK_ACTIONS_TASK_STATUS_URL = BIFROST_BRIDGE_BASE_URL + "/ws/keycloak-actions-task-status/:task_id/";
export const DOWNLOAD_DOCUMENTS_TASK_STATUS_URL = BIFROST_BRIDGE_BASE_URL + "/ws/paysheets-bulk-downloading-status/:task_id/";
export const PAYSHEETS_GENERATION_TASK_STATUS_URL = BIFROST_BRIDGE_BASE_URL + "/ws/paysheets-generation-task-status/:task_id/";


// Identity Service Endpoints
export const IDENTITY_SERVICE_BASE_URL = `${import.meta.env.VITE_IDENTITY_SERVICE_BASE_URL}`;
export const FETCH_CREATE_USERS_URL = IDENTITY_SERVICE_BASE_URL + "/users/";
export const FETCH_UPDATE_DELETE_USER_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/";
export const COMPLETE_USER_PROFILE_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/complete_profile/";
export const FETCH_USER_CONTRACTS_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/contract/";
export const ADD_CONTRACT_TO_USER_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/add_contract/";
export const FETCH_COMPLETE_USER_CONTRACT_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/contracts/:contract_id/";
export const GET_USER_COMPANY = FETCH_UPDATE_DELETE_USER_URL + "/company/";
export const UPDATE_USER_DATA_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/additional_infos/";
export const REGISTER_ADMIN_URL = IDENTITY_SERVICE_BASE_URL + "/users/register_admin/";
export const UPDATE_ADMIN_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/update_admin/";
export const ADMIN_INVITATION_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/invite_admin/";
export const COLLABO_INVITATION_URL = IDENTITY_SERVICE_BASE_URL + "/users/invite_collabos/";
export const CHANGE_ROLE_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/change_role/";
export const BLOCK_UNBLOCK_REMOVE_USER_ACCESS_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/kc_status/";
export const CHANGE_COMPANY_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/change_company/";

//notifications
export const USER_NOTIFICATION_URL = IDENTITY_SERVICE_BASE_URL + "/notifications/";
export const UPDATE_NOTIFICATION_URL = IDENTITY_SERVICE_BASE_URL + "/notifications/:id/change_status/";
export const DELETE_NOTIFICATION_URL = IDENTITY_SERVICE_BASE_URL + "/notifications/:id/";

// Contract
export const END_CONTRACT_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/contracts/end/:contract_id/";
export const CANCEL_END_CONTRACT_URL = IDENTITY_SERVICE_BASE_URL + "/users/:id/contracts/cancel_end_contract/:contract_id/";


//Bank endpoint
export const ADD_FETCH_BANK_URL = IDENTITY_SERVICE_BASE_URL + "/banks/";
export const FETCH_PATCH_DELETE_BANK_URL = IDENTITY_SERVICE_BASE_URL + "/banks/:id/";

//Signatory endpoint
export const ADD_FETCH_SIGNATORY_URL = IDENTITY_SERVICE_BASE_URL + "/signatories/";
export const FETCH_PATCH_DELETE_SIGNATORY_URL = IDENTITY_SERVICE_BASE_URL + "/signatories/:id/";


// Absence Service Endpoints
export const ABSENCES_SERVICES_BASE_URL = `${import.meta.env.VITE_ABSENCE_SERVICE_BASE_URL}`;
export const FETCH_ABSENCES_URL = ABSENCES_SERVICES_BASE_URL + "/absences/";
export const FETCH_UPDATE_DELETE_ABSENCES_URL = ABSENCES_SERVICES_BASE_URL + "/absences/:id/";
export const ADD_ABSENCES_TO_USERS_URL = ABSENCES_SERVICES_BASE_URL + "/users/bulk_absences/";
export const ADD_FETCH_USER_ABSENCES_URL = ABSENCES_SERVICES_BASE_URL + "/users/:id/absences/";
export const FETCH_ABSENCES_TYPES_URL = ABSENCES_SERVICES_BASE_URL + "/absences_types/";
export const FETCH_ABSENCES_EVENTS_URL = ABSENCES_SERVICES_BASE_URL + "/absences_events/";
export const FETCH_ABSENCES_DAILY_STATS_URL = ABSENCES_SERVICES_BASE_URL + "/absences/daily_stats/";
export const FETCH_USER_LEAVE_BALANCE_URL = ABSENCES_SERVICES_BASE_URL + "/users/:id/leave_balance/";
export const FETCH_LEAVES_BALANCES_URL = ABSENCES_SERVICES_BASE_URL + "/leaves_balances/";

// AWARDS Service Endpoints
export const AWARDS_SERVICES_BASE_URL = `${import.meta.env.VITE_AWARDS_SERVICE_BASE_URL}`;
export const FETCH_AWARDS_URL = AWARDS_SERVICES_BASE_URL + "/awards/";
export const AWARDS_GRANTED_BY_MONTH_URL = AWARDS_SERVICES_BASE_URL + "/awards/granted-by-month/";
export const BULK_ADD_AWARDS_TO_USERS_URL = AWARDS_SERVICES_BASE_URL + "/users/bulk-awards/";
export const ADD_AWARDS_TO_USER_URL = AWARDS_SERVICES_BASE_URL + "/users/:id/awards/";
export const ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL = AWARDS_SERVICES_BASE_URL + "/users/:id/awards/";
export const GET_PATCH_DELETE_AWARD_URL = AWARDS_SERVICES_BASE_URL + "/awards/:id/";
export const FETCH_USER_NEXT_AWARDS_URL = AWARDS_SERVICES_BASE_URL + "/users/:id/next_awards/";
export const FETCH_AWARDS_TYPES_URL = AWARDS_SERVICES_BASE_URL + "/awards-types/";

// REGULATION Service Endpoints
export const REGULATIONS_SERVICES_BASE_URL = `${import.meta.env.VITE_REGULATION_SERVICE_BASE_URL}`;
export const ADD_FETCH_DELETE_REGULATIONS_URL = REGULATIONS_SERVICES_BASE_URL + "/regulations/";
export const CANCEL_REGULATIONS_URL = REGULATIONS_SERVICES_BASE_URL + "/regulations/cancel/";
export const PREVIOUS_REGULATIONS_URL = REGULATIONS_SERVICES_BASE_URL + "/regulations/previous_regulation/";
export const ADD_FETCH_USER_REGULATIONS_URL = REGULATIONS_SERVICES_BASE_URL + "/users/:id/regulations/";
export const GET_PATCH_DELETE_REGULATIONS_URL = REGULATIONS_SERVICES_BASE_URL + "/regulations/:id/";


// Settings Service Endpoints
export const SETTINGS_SERVICES_BASE_URL = `${import.meta.env.VITE_SETTINGS_SERVICE_BASE_URL}`;
export const JOB_BASE_URL = SETTINGS_SERVICES_BASE_URL + "/job/";
export const FETCH_PAYSHEETS_GENERATION_JOBS = JOB_BASE_URL + "paysheets_generation/";
export const FETCH_SETTINGS_CONSTANTS = SETTINGS_SERVICES_BASE_URL + "/settings/";
export const FETCH_BUSINESS_SECTOR = SETTINGS_SERVICES_BASE_URL + "/sector/"

// Boarding endpoints
export const CGU_ACCEPTATION_URL = SETTINGS_SERVICES_BASE_URL + "/boarding/accept_cgu/";
export const INFOS_GENERALS_URL = SETTINGS_SERVICES_BASE_URL + "/boarding/infos_generals/";
export const ABSENCES_LEAVES_URL = SETTINGS_SERVICES_BASE_URL + "/boarding/conges_absences/";
export const ORGANISATION_URL = SETTINGS_SERVICES_BASE_URL + "/boarding/organisation/";

// Paysheet Service Endpoints
export const PAYSHEET_BASE_URL = `${import.meta.env.VITE_PAYSHEET_SERVICE_BASE_URL}`;
export const CREATE_USERS_PAYSHEETS_URL = PAYSHEET_BASE_URL + "/paysheets/";
export const FETCH_PAYSHEETS_URL = PAYSHEET_BASE_URL + "/paysheets/";
export const FETCH_USER_PAYSHEETS_URL = PAYSHEET_BASE_URL + "/users/:id/paysheets/";
export const FETCH_USER_SALARY_AVG = PAYSHEET_BASE_URL + "/users/:id/salary_avg/";
export const FETCH_USERS_SALARY_AVG = PAYSHEET_BASE_URL + "/users/salary_avg/";
export const FETCH_USERS_LATEST_SALARY = PAYSHEET_BASE_URL + "/users/:id/latest_salary/";

// SalaryAdvances Service Endpoints
export const SALARY_ADVANCES_SERVICES_BASE_URL = `${import.meta.env.VITE_SALARY_ADVANCES_SERVICE_BASE_URL}`;
export const FETCH_USER_WITH_SALARY_ADVANCES_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/";
export const ADD_FETCH_SALARY_ADVANCES_USERS_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/:id/salary-advances/";
export const FETCH_USER_REIMBURSEMENTS_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/:id/reimbursements/";
export const ADD_FETCH_REIMBURSEMENT_SALARY_ADVANCE_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/:id/salary-advances/:advance_id/reimbursements/";
export const FETCH_PATCH_DELETE_SALARY_ADVANCE_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/salary-advances/:id/";
export const FETCH_ALL_SALARY_ADVANCE_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/salary-advances/";
export const FETCH_ALL_REIMBURSEMENTS_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/reimbursements/";
export const FETCH_PATCH_DELETE_REIMBURSEMENT_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/reimbursements/:id/";
export const FETCH_USER_REMAINING_REIMBURSEMENT_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/:id/remaining_reimbursement/";
export const FETCH_USERS_REMAINING_REIMBURSEMENTS_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/reimbursements/remaining_reimbursements/";

export const ADD_FETCH_ACOMPTES_USERS_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/users/:id/acomptes/";
export const FETCH_ALL_ACOMPTES_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/acomptes/";
export const FETCH_PATCH_DELETE_ACOMPTES_URL = SALARY_ADVANCES_SERVICES_BASE_URL + "/acomptes/:id/";


// TeamService endpoints
export const TEAMS_SERVICES_BASE_URL = `${import.meta.env.VITE_TEAMS_SERVICE_BASE_URL}`;
export const FETCH_PATCH_DELETE_TEAMS_URL = TEAMS_SERVICES_BASE_URL + "/teams/";
export const ADD_MEMBERS_TEAMS_URL = TEAMS_SERVICES_BASE_URL + "/teams/bulk-users/";
export const BULK_TEAMS_UPDATE_URL = TEAMS_SERVICES_BASE_URL + "/teams/:id/bulk-teams-update/";
export const REMOVE_MEMBER_TEAMS_URL = TEAMS_SERVICES_BASE_URL + "/teams/:id/users/";


// DOCUMENTS Service Endpoints
export const DOCUMENTS_SERVICE_BASE_URL = `${import.meta.env.VITE_DOCUMENTS_SERVICE_BASE_URL}`;
export const ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/";
export const GET_DOCUMENT_CONTENT_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/contents/";
export const GET_ZIPPED_PAYSHEETS_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/serve-zipped-pay-slips/";
export const FETCH_UPDATE_DELETE_DOCUMENT_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/";
export const RESTORE_DOCUMENT_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/restore/";
export const SHARE_DOCUMENT_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/share/";
export const UNSHARE_DOCUMENT_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/unshare/";
export const GET_DOCUMENT_VIEWERS_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/viewers/";
export const DISPATCH_USERS_SLIPS_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/dispatch-pay-slips/";
export const CHANGE_DOCUMENT_PERMISSIONS_URL = DOCUMENTS_SERVICE_BASE_URL + "/documents/:id/change-perms/";
export const FETCH_USER_PAYSHEETS_PDF_PATH_URL = DOCUMENTS_SERVICE_BASE_URL + "/users/paysheets/";
export const GET_USER_DOCUMENTS_URL = DOCUMENTS_SERVICE_BASE_URL + "/users/:user_id/documents/";
