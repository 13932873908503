import React from "react";
import {Card, CardContent} from "@mui/material";

import {Tab, TabItem} from "comp_common/tab";
import {NotificationItem} from "pag_collabo/notifications/NotificationItem";
import { useAppSelector} from "@/hooks/reduxToolkitHook";


export const Notification = () => {
    const { userNotification } = useAppSelector((state) => state.users);

    // const users = useState(state => state.user);

    return (
        <Card className="max-w-[380px]">
            <div className="w-full flex items-center py-3 px-6 border-b-2 border-gray-100">
                <p className="text--S4">Notifications</p>
            </div>
            <Tab className="!inline-flex pl-2">
                <TabItem
                    className=""
                    value="tab_1"
                    tabId="tab_1"
                    // onChange={(e) => handleTabChange(e)}
                    // isChecked={selectedTab === "tab_1electedTab === "tab_1"}
                    isChecked={true}
                    labelContent="Tout"
                />
                {/*<TabItem*/}
                {/*    className=""*/}
                {/*    value="tab_2"*/}
                {/*    tabId="tab_2"*/}
                {/*    isChecked={false}*/}
                {/*    labelContent="Actions bloquantes"*/}
                {/*/>*/}
            </Tab>
            <CardContent className="max-h-96 overflow-y-scroll !p-0">
                {
                    userNotification.length > 0 ?
                        <>
                            {
                                userNotification.map((notification) => <NotificationItem notification={notification} key={notification.id}/>)
                            }
                        </> :
                        <div className="w-[350px] py-3 px-6">
                            Aucune notification
                        </div>
                }
            </CardContent>
        </Card>
    );
}