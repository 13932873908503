import React, {useState, useRef, useEffect, ReactNode, MouseEventHandler} from "react";
import {useSpring, animated} from "react-spring";
import "./Button.scss";
import {CircularProgress} from "@mui/material";
import {grey} from "@mui/material/colors";



const STYLES = [
    "btn--default",
    "btn--danger",
    "btn--success",
    "btn--outline--default",
    "btn--outline--danger",
    "btn--outline--success",
];
const SIZES = ["btn--medium", "btn--small"];

export type ButtonPropsType = {
    children?: ReactNode,
    className?: string,
    type?: "reset" | "button" | "submit" | undefined,
    isDisabled?: boolean,
    onClick?: MouseEventHandler<HTMLButtonElement> ,
    buttonStyle?: string,
    buttonSize?: string,
    iconClasses?: string,
    iconSide?: string,
    isLoading?: boolean,
}

export default function Button({
                                   children,
                                   className,
                                   type,
                                   isDisabled,
                                   onClick,
                                   buttonStyle,
                                   buttonSize,
                                   iconClasses,
                                   iconSide,
                                   isLoading,
                               }: ButtonPropsType) {

    const checkButtonStyle = buttonStyle && STYLES.includes(buttonStyle)
        ? buttonStyle
        : STYLES[0];
    const checkButtonSize = buttonSize && SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

    // https://humble.dev/creating-a-nice-loading-button-with-react-hooks  -> button with loader
    // les lignes suivantes sont pour que la largeur du boutton reste constante  malgré le loader
    const [width, setWidth] = useState<number | undefined>(0);
    const [height, setHeight] = useState<number | undefined>(0);
    const ref = useRef<HTMLButtonElement>(null);
    const rect = ref?.current?.getBoundingClientRect();
    const [showLoader, setShowLoader] = useState(false);

    useEffect(() => {
        if (ref.current && ref.current.getBoundingClientRect().width) {
            setWidth(rect?.width);
        }
        if (ref.current && ref.current.getBoundingClientRect().height) {
            setHeight(ref.current.getBoundingClientRect().height);
        }

        if (isLoading) {
            setShowLoader(true);
            isDisabled = true;
        }

        // Show loader a bits longer to avoid loading flash
        if (!isLoading && showLoader) {
            const timeout = setTimeout(() => {
                setShowLoader(false);
                isDisabled = false;
            }, 400);

            return () => {
                clearTimeout(timeout);
            };
        }
        return;
    }, [isLoading, showLoader]);
    // ceci c'est pour le loading ne s'arrête pas brutalement

    // Hooks used to fade in/out the loader or the button contents
    const fadeOutProps = useSpring({opacity: showLoader ? 1 : 0});
    const fadeInProps = useSpring({opacity: showLoader ? 0 : 1});

    return (
        <button
            disabled={isDisabled}
            className={`btn ${checkButtonStyle} ${checkButtonSize} ${className} justify-center items-center`}
            onClick={onClick}
            type={type}
            ref={ref}
            style={showLoader ? {width: `${width}px`, height: `${height}px`} : {}}>
            {iconSide === "left" && (
                <span className="iconClasses--left">
          {iconClasses && <i className={`mr-2 ${iconClasses}`}/>}
        </span>
            )}
            <span className="text--button ">
        {showLoader ? (
            <animated.div style={fadeOutProps}>
                <CircularProgress size={20} style={{color: grey[100], display: 'block'}}/>
            </animated.div>
        ) : (
            <animated.div style={fadeInProps}>{children}</animated.div>
        )}
      </span>
            {iconSide === "right" && (
                <span className="iconClasses--right">
          {iconClasses && <i className={`ml-2 ${iconClasses}`}/>}
        </span>
            )}
        </button>
    );
}
