import axios from "axios";
import { IDENTITY_SERVICE_BASE_URL } from "@/constants/endpoints";
import keycloak from "@/utils/keycloakUtils";
import { getRelatedResourceId, getServiceAndEndpointFromPath } from "@/utils/utils";


const http = axios.create({
  baseURL: IDENTITY_SERVICE_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

// Request interceptor
http.interceptors.request.use(
  (config) => {
    // config.headers!.App = "uptimise-app";
    config.headers!.Authorization = `Bearer ${keycloak.token}`;
    const urlInfos = getServiceAndEndpointFromPath(config.url!);
    if (urlInfos) {
      config.params = {
        ...config.params,
        "resource_id": getRelatedResourceId(urlInfos.serviceName, urlInfos.endpoint, config.method!),
      };
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Response interceptor
http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      await keycloak.updateToken(5);
      axios.defaults.headers.common["Authorization"] = `Bearer ${keycloak.token}`;
      return http(originalRequest);
    }
    return Promise.reject(error.response);
  },
);

export { http };
