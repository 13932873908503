export const enum AbsencesTypesTags {
  REMUNERATED_ABSENCE = "REMUNERATED_ABSENCE",
  UNPAID = "UNPAID",
  PAID = "PAID",
  SICK = "SICK",
  MATERNITY_ABSENCE = "MATERNITY_ABSENCE",
  FAMILIAL_EVENT = "FAMILIAL_EVENT",
  START_WORK = "START_WORK",
  END_WORK = "END_WORK"
}

export const All_ABSENCES_TYPES_TAGS = [
  AbsencesTypesTags.REMUNERATED_ABSENCE,
  AbsencesTypesTags.UNPAID,
  AbsencesTypesTags.PAID,
  AbsencesTypesTags.SICK,
  AbsencesTypesTags.MATERNITY_ABSENCE,
  AbsencesTypesTags.FAMILIAL_EVENT,
  AbsencesTypesTags.START_WORK,
];