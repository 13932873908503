import React from "react";
// TODO replace react tabs package
// @ts-ignore
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./Sidebar.scss";
import MenuCompany from "comp_rh/menu/MenuCompany";
import MenuCollabo from "comp_collabo/menu/MenuCollabo";
import { useHistory, useLocation } from "react-router-dom";
import menus from "@/constants/appMenus";


const Sidebar = () => {

  const history = useHistory();
  const location = useLocation();

  const subMenus = menus.flatMap(x => x.subMenus);

  const handleTabChange = (index: number) => {
    if (index === 0) {
      history.push("/");
    } else {
      history.push("/ajout-collaborateur");
    }
  };

  const getTabIndex = (route: string) => {
    if (["/", "/dashboard", "/monthly-payslip-pending"].includes(route)) {
      return 0;
    }
    return subMenus.find(x => route == x.link) ?
      0 : route.startsWith("/ajout-collaborateur") ? 1 : 0;
  };

  return (
    <aside className="flex--1 bg-white overflow-y-hidden">
      <Tabs
        className="sidebar-wrapper"
        defaultIndex={getTabIndex(location.pathname)}
        onSelect={(index: number) => handleTabChange(index)}>
        <TabList>
          <Tab>Entreprise</Tab>
          <Tab>Collaborateurs</Tab>
        </TabList>

        <TabPanel>
          <MenuCompany />
        </TabPanel>
        <TabPanel className="overflow-y-hidden">
          <MenuCollabo />
        </TabPanel>
      </Tabs>
    </aside>
  );
};

export default Sidebar;
