import React, { Component } from "react";
import PageError from "@/pages/error-pages/PageError";
import { ENVIRONMENT } from "@/constants/settings";

import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (ENVIRONMENT)
      Sentry.captureException(error, { extra: info });

    console.log(error);
  }

  render() {
    if (this.state.hasError) {
      return <PageError />;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default ErrorBoundary;
