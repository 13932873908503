import React, { forwardRef, ReactNode } from "react";
import "./Dropdown.scss";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";

export type DropdownProps = {
  selected?: string,
  placeholder?: string,
  options?: Array<{ label: string, value: string }>,
  className?: string,
  onChange?: (event: SelectChangeEvent, child: ReactNode) => void
}
export const Dropdown = forwardRef(
    ({selected, placeholder, options, className, onChange}: DropdownProps, ref) => (
        <Select
            ref={ref}
            defaultValue={selected ? selected : options && options[0]?.value}
            onChange={onChange}
            input={
                <OutlinedInput
                    className={`${className} dropdown form--custom w-full`}
                />
            }
            inputProps={{"aria-label": "Without label"}}
        >
            <MenuItem disabled value="">
                <em>{placeholder}</em>
            </MenuItem>
            {options?.map((opt) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.label}
                </MenuItem>
            ))}
        </Select>
    )
);
