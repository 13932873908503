import {
  ADD_FETCH_ACOMPTES_USERS_URL,
  ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL,
  ADD_FETCH_DELETE_REGULATIONS_URL,
  ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL,
  ADD_FETCH_SALARY_ADVANCES_USERS_URL,
  ADD_FETCH_USER_ABSENCES_URL,
  ADD_FETCH_USER_REGULATIONS_URL,
  AWARDS_GRANTED_BY_MONTH_URL,
  FETCH_ABSENCES_DAILY_STATS_URL,
  FETCH_ABSENCES_URL,
  FETCH_ALL_ACOMPTES_URL,
  FETCH_ALL_REIMBURSEMENTS_URL,
  FETCH_ALL_SALARY_ADVANCE_URL,
  FETCH_AWARDS_URL, FETCH_BUSINESS_SECTOR,
  FETCH_LEAVES_BALANCES_URL,
  FETCH_PAYSHEETS_GENERATION_JOBS,
  FETCH_PAYSHEETS_URL,
  FETCH_SETTINGS_CONSTANTS,
  FETCH_USER_LEAVE_BALANCE_URL,
  FETCH_USER_PAYSHEETS_PDF_PATH_URL,
  FETCH_USER_PAYSHEETS_URL,
  FETCH_USER_REIMBURSEMENTS_URL,
  FETCH_USER_REMAINING_REIMBURSEMENT_URL,
  FETCH_USER_SALARY_AVG, FETCH_USERS_LATEST_SALARY,
  FETCH_USERS_REMAINING_REIMBURSEMENTS_URL,
  FETCH_USERS_SALARY_AVG,
  GET_DOCUMENT_CONTENT_URL,
  GET_DOCUMENT_VIEWERS_URL,
  GET_USER_COMPANY,
  PREVIOUS_REGULATIONS_URL,
} from "@/constants/endpoints";
import { http } from "@/utils/http";
import { JobType } from "@/types/SettingsType";
import { PaysheetLinesRef, UserPaysheet } from "@/types/paysheetDataType";
import { LeaveBalanceType, UserAbsenceType } from "@/types/api/UserAbsenceType";
import { SettingsPaysheetLines } from "@/constants/settings";
import {
  AcompteType,
  ReimbursementsType,
  RemainReimbursementType,
  SalaryAdvancesType,
} from "@/types/api/UserSalaryAdvancesType";
import { UserAwardsType } from "@/types/api/UserAwardsType";
import { RegulationsTypes, UserRegulationsType } from "@/types/api/UserRegulationsType";
import { FileStructure } from "pag_collabo/folders/types";


export type GetAbsences = {
  filter_by: "created_at" | "valid",
  month: string
}

export const getAbsences = async (params: GetAbsences) => {
  return http.get(
    FETCH_ABSENCES_URL,
    {
      params: {
        filter_by: params.filter_by,
        month: params.month,
      },
    },
  ).then(res => res.data.data as UserAbsenceType[]);
};

export const getUserAbsences = async (id: string, params: { month: string }) =>
  http.get(ADD_FETCH_USER_ABSENCES_URL.replace(":id", id) + `?month=${params.month}`)
    .then(res => res.data.data);

export const getLeavesBalances = async () => {
  return http.get(
    FETCH_LEAVES_BALANCES_URL,
  ).then(res => res.data.data as LeaveBalanceType[]);
};

export const getUserLeaveBalance = async (id: string) =>
  http.get(
    FETCH_USER_LEAVE_BALANCE_URL
      .replace(":id", id),
  )
    .then(res => res.data.data as LeaveBalanceType[]);

export const getAbsencesStatistics = async () =>
  http.get(
    FETCH_ABSENCES_DAILY_STATS_URL,
  ).then(res => res.data.data as { abs_type: string, count: number }[]);

export const getUserCompany = (id: string) =>
  http.get(
    GET_USER_COMPANY
      .replace("/id/", `/${id}/`),
  )
    .then(res => res.data.data);

export const getUserIndemnity = async (id: string, params?: { month?: string, type: string }) => http
  .get(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", id), { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getUserNextIndemnity = async (id: string, params?: { month?: string | null, type: string }) => http
  .get(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", id), { params: { ...params, filter_next: true } })
  .then(res => res.data.data as RegulationsTypes[],
  );

export const getUsersLastIndemnity = async (params?: { month?: string | null, type: string }) => http
  .get(PREVIOUS_REGULATIONS_URL, { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getUsersIndemnity = async (params?: { month?: string | null, type: string }) => http
  .get(ADD_FETCH_DELETE_REGULATIONS_URL, { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getPaysheetsGenerationJob = async () =>
  http.get(FETCH_PAYSHEETS_GENERATION_JOBS).then(res => res.data.data as JobType | undefined | null);

export const getUserPaysheets = async (id: string, params?: { month?: string, second_month?: string, }) =>
  http
    .get(FETCH_USER_PAYSHEETS_URL.replace(":id", id), {
      params,
    })
    .then(res => res.data.data as UserPaysheet[]);

export const getPaysheets = async (params?: { month?: string, second_month?: string, }) => http
  .get(FETCH_PAYSHEETS_URL, {
    params,
  })
  .then(res => res.data.data as UserPaysheet[]);

export const getSettingsConstants = (slug?: string) => http
  .get(FETCH_SETTINGS_CONSTANTS, { params: { slug } })
  .then(res => res.data.data);

export const getPaysheetLinesRef = async () => {
  return await getSettingsConstants(SettingsPaysheetLines) as PaysheetLinesRef[];
};

export const getUserAwards = async (id: string, params?: { month?: string }) => http
  .get(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", id), { params })
  .then(res => res.data.data as UserAwardsType[]);

export const getUserNextAwards = async (id: string, params?: { month?: string | null }) => http
  .get(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", id), { params: { ...params, filter_next: true } })
  .then(res => res.data.data as UserAwardsType[],
  );

export const getUsersAwards = async ({
                                       month,
                                       filter_next = false,
                                     }: { month?: string | null, filter_next?: boolean }) => http
  .get(FETCH_AWARDS_URL, { params: { month, filter_next } })
  .then(res => res.data.data as UserAwardsType[]);

export const getAwardsGrantedByMonth = async ({
                                                month,
                                              }: { month?: string | null }) => http
  .get(AWARDS_GRANTED_BY_MONTH_URL, { params: { month } })
  .then(res => res.data.data as UserAwardsType[]);


export const getUserDeductions = async (id: string, params?: { month?: string, type: string }) => http
  .get(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", id), { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getUserNextDeductions = async (id: string, params?: { month?: string | null, type: string }) => http
  .get(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", id), { params: { ...params, filter_next: true } })
  .then(res => res.data.data as RegulationsTypes[],
  );

export const getUsersAcomptes = async (params?: { month?: string | null }) => http
  .get(FETCH_ALL_ACOMPTES_URL, { params })
  .then(res => res.data);

export const getUsersDeductions = async (params?: { month?: string | null, type: string }) => http
  .get(ADD_FETCH_DELETE_REGULATIONS_URL, { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getUsersLastDeductions = async (params?: { month?: string | null, type: string }) => http
  .get(PREVIOUS_REGULATIONS_URL, { params })
  .then(res => res.data.data as UserRegulationsType[]);

export const getUsersPaysheetsByMonth = async (params: { month: string }) => http
  .get(FETCH_USER_PAYSHEETS_PDF_PATH_URL, { params })
  .then(res => res.data.data as [{ user_id: string, file: string }]);

export const getUserSalaryAvg = async (id: string, params?: { limit: number }) => http
  .get(FETCH_USER_SALARY_AVG.replace(":id", id), { params })
  .then(res => res.data.data as number);

export const getUsersSalaryAvg = async (users: string[]) => {
  try {
    const res = http.post(FETCH_USERS_SALARY_AVG, { users }).then(res => res.data.data);
    return await res;
  } catch (err) {
    console.error(err);
  }
};

export const getUsersLatestSalaries = async (id: string, params?: { limit: number }) => http
  .get(FETCH_USERS_LATEST_SALARY.replace(":id", id), { params })
  .then(res => res.data.data as {salary: number, month: string}[]);

export const getUserSalaryAdvance = async (id: string, params?: { month: string }) => http
  .get(ADD_FETCH_SALARY_ADVANCES_USERS_URL.replace(":id", id), { params })
  .then(res => res.data.data as SalaryAdvancesType[]);

export const getUsersSalariesAdvances = async (params?: { month: string }) => http
  .get(FETCH_ALL_SALARY_ADVANCE_URL, { params })
  .then(res => res.data.data as SalaryAdvancesType[]);

export const getUserAcomptes = async (id: string, params?: { month: string }) => http
  .get(ADD_FETCH_ACOMPTES_USERS_URL.replace(":id", id), { params })
  .then(res => res.data.data as AcompteType[]);


export const getUserReimbursement = async (id: string, params?: { month: string }) => http
  .get(FETCH_USER_REIMBURSEMENTS_URL.replace(":id", id), { params })
  .then(res => res.data.data as ReimbursementsType[]);

export const getUsersReimbursements = async (params: { month: string }) => http
  .get(FETCH_ALL_REIMBURSEMENTS_URL, { params })
  .then(res => res.data.data as ReimbursementsType[]);

export const geUsersRemainingReimbursements = async (params: { month: string }) => http
  .get(FETCH_USERS_REMAINING_REIMBURSEMENTS_URL, { params })
  .then(res => res.data.data as RemainReimbursementType[]);

export const geUserRemainingReimbursement = async (id: string, params?: { month: string }) => http
  .get(FETCH_USER_REMAINING_REIMBURSEMENT_URL.replace(":id", id), { params })
  .then(res => res.data.data as RemainReimbursementType);

export const getCompanyRootDocument = async () => http
  .get(ADD_FETCH_COMPANY_ROOT_DOCUMENTS_URL.replace(":resource_id", "documents_list"))
  .then(res => res.data.data as FileStructure[]);

export const getFolderContent = async (id: string) => http
  .get(GET_DOCUMENT_CONTENT_URL.replace("/:id/", `/${id}/`))
  .then(res => res.data.data as FileStructure);

export const getDocumentViewers = async (id: string) => http
  .get(GET_DOCUMENT_VIEWERS_URL.replace("/:id/", `/${id}/`))
  .then(res => res.data.data);

export const getFileContent = async (id: string) => http
  .get(
    GET_DOCUMENT_CONTENT_URL
      .replace("/:id/", `/${id}/`),
    { responseType: "blob", timeout: 30000 },
  )
  .then(res => res);
// .then(res => res.data.data as UserAwardsType[]);

export const getBusinessSector = async () =>
  http.get(FETCH_BUSINESS_SECTOR).then(res => res.data);