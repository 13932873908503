import React, { useEffect, useState } from "react";
import { Avatar as LetterAvatar, IconButton, Tooltip } from "@mui/material";
import { colorArray, getUserFullName } from "@/utils/utils";
import { DeleteRounded, PhotoCamera } from "@mui/icons-material";
import "./avatar.scss";
import "cropperjs/dist/cropper.css";
import { User } from "@/types/UserType";
import { http } from "@/utils/http";
import { FETCH_UPDATE_DELETE_USER_URL } from "@/constants/endpoints";
import { useAppDispatch } from "@/hooks/reduxToolkitHook";
import { updateUsersList } from "@/redux/features/usersSlice";

type AvatarProps = {
  user: User;
  backgroundColor?: string;
  isEditable?: boolean;
  size?: "md" | "lg";
}

const Avatar = ({ user, backgroundColor, isEditable, size = "md" }: AvatarProps) => {

  const dispatch = useAppDispatch();

  const [initials, setInitials] = useState("");
  const [, setUserData] = useState<User>();

  const userName = getUserFullName(user, true);
  const src = user.photo ?? "";
  let defaultBackground;

  useEffect(() => {
    setInitials(`${userName.split(" ")[0][0]}${userName.split(" ")[1][0]}`);
    setUserData(user);
  }, []);

  const sumChars = (str: string) => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += str.charCodeAt(i);
    }
    return sum;
  };

  if (/[A-Z]/.test(initials)) {
    let index = sumChars(userName) % colorArray.length;
    if (backgroundColor) {
      defaultBackground = backgroundColor;
    } else {
      defaultBackground = colorArray[index];
    }
  } else if (/[\d]/.test(initials)) {
    defaultBackground = colorArray[parseInt(initials)];
  } else {
    defaultBackground = "#051923";
  }


  const avatarUpload = (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("photo", e.target.files[0]);

    // TODO: alert on upload succeeded or not

    http.patch(FETCH_UPDATE_DELETE_USER_URL.replace(":id", user.id), formData)
      .then(res => {
        dispatch(updateUsersList({ user: res.data, target: "users" }));
      })
      .catch(err => console.log("Error : ", err),
      );
  };

  return (
    <>
      <div className={`relative ${size}`} id="avatar">
        {
          isEditable &&
          <div
            id="actions"
            className="absolute z-10 bg-blue-200 rounded-full w-full h-full flex items-center justify-center"
          >
            <Tooltip title="Modifier">
              <IconButton color="primary" aria-label="upload picture" component="label">
                <input hidden accept="image/*" type="file" onChange={avatarUpload} />
                <PhotoCamera />
              </IconButton>
            </Tooltip>
            {
              src &&
              <Tooltip title="Supprimer">
                <IconButton aria-label="delete">
                  <DeleteRounded color="error" />
                </IconButton>
              </Tooltip>
            }

          </div>
        }

        {
          src ?
            <LetterAvatar
              src={src}
              alt={userName}
              className={`${size}`}
            />
            :
            <LetterAvatar
              children={initials}
              className={`${size}`}
              sx={{
                backgroundColor: `${defaultBackground}`,
              }}
            />
        }
      </div>
    </>
  );
};

export default Avatar;
