import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./redux/features/usersSlice";
import absencesReducer from "./redux/features/absencesSlice";
import awardsReducer from "./redux/features/awardsSlice";
import documentsReducer from "./redux/features/documentsSlice";
// import indemnityReducer from "./redux/features/IndemnitySlice";
import deductionsReducer from "./redux/features/regulationsSlice";
import paysheetsReducer from "./redux/features/paysheetsSlice";
import salaryAdvancesReducer from "./redux/features/salaryAdvancesSlice";
import alertReducer from "./redux/features/alertsSlice";
import targetMonthReducer from "./redux/features/targetMonthSlice";
import teamReducer from "./redux/features/teamsSlice";

export const store = configureStore({
  reducer: {
    alerts: alertReducer,
    users: userReducer,
    absences: absencesReducer,
    awards: awardsReducer,
    regulations: deductionsReducer,
    documents: documentsReducer,
    // indemnities: indemnityReducer,
    paysheets: paysheetsReducer,
    salaryAdvances: salaryAdvancesReducer,
    targetMonth: targetMonthReducer,
    teams: teamReducer,
  },
  // devTools: ENVIRONMENT === "development",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

