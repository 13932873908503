import Keycloak from "keycloak-js";
import {KEYCLOAK_AUTH_ACCESS_CLIENT_ID, KEYCLOAK_AUTH_ACCESS_REALM, KEYCLOAK_BASE_URL} from "@/constants/endpoints";
import {publish} from "@/utils/events";

//keycloak initialization options
const keycloak = new Keycloak({
  url: KEYCLOAK_BASE_URL,
  realm: KEYCLOAK_AUTH_ACCESS_REALM,
  clientId: KEYCLOAK_AUTH_ACCESS_CLIENT_ID,
});

export const getUserKcInfos = () => {
  return keycloak.loadUserProfile().then((user) => {
    // localStorage.setItem("userInfos", JSON.stringify(user));
    publish("userKcInfosReady", user);
  });
};


// keycloak.onTokenExpired = () => {
//   keycloak.updateToken(1800).then(() => {
//     console.log("Got a new token");
//   }).catch(() => console.log("Token expired"));
// };

export const refreshKcToken = async () => {
  const a = await keycloak.updateToken(5);
};
export default keycloak;
