export const AWARD_FREQUENCIES = {
  ONCE: 0,
  MONTHLY: 1,
  BIMONTHLY: 2,
  QUARTERLY: 3,
  BIANNUAL: 6,
  ANNUAL: 12,
};


export const AWARD_FREQUENCIES_HUMAN_NAMES = {
  0: "Une fois",
  1: "Mensuelle",
  2: "Bimensuelle",
  3: "Trimestrielle",
  6: "Semestrielle",
  12: "Annuelle"
};

export const DEFAULT_FREQUENCIES = [
  {
    label: "Une fois",
    value: AWARD_FREQUENCIES.ONCE,
  },
  {
    label: "Mensuelle",
    value: AWARD_FREQUENCIES.MONTHLY,
  },
  {
    label: "Bimensuelle",
    value: AWARD_FREQUENCIES.BIMONTHLY,
  },
  {
    label: "Trimestrielle",
    value: AWARD_FREQUENCIES.QUARTERLY,
  },
  {
    label: "Semestrielle",
    value: AWARD_FREQUENCIES.BIANNUAL,
  },
  {
    label: "Annuelle",
    value: AWARD_FREQUENCIES.ANNUAL,
  },
];
