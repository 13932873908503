export const AWARD_TYPES_POSSIBLE_VALUES = {

  PRIME_ANCIENNETE: "PRIME_ANCIENNETE",
  PRIME_PERFORMANCE: "PRIME_PERFORMANCE",
  PRIME_RISQUE: "PRIME_RISQUE",
  PRIME_RESIDENCE: "PRIME_RESIDENCE",
  PRIME_SALISSURE: "PRIME_SALISSURE",
  PRIME_CAISSE: "PRIME_CAISSE",
  PRIME_PANIER: "PRIME_PANIER",
  PRIME_NUIT: "PRIME_NUIT",
  PRIME_RENDEMENT: "PRIME_RENDEMENT",
  PRIME_GARDE: "PRIME_GARDE",
  PRIME_ASTREINTE: "PRIME_ASTREINTE",
  PRIME_RESPONSABILITE: "PRIME_RESPONSABILITE",
  PRIME_FONCTION: "PRIME_FONCTION",
  PRIME_REPRESENTATION: "PRIME_REPRESENTATION",
  PRIME_SUJETION: "PRIME_SUJETION",
  PRIME_TREIZIEME_MOIS: "PRIME_TREIZIEME_MOIS",
  GRATIFICATIONS: "GRATIFICATIONS",

  AUTRE: "AUTRE",
};

export const SENIORITY_AWARD_TYPE_MODEL =
  {
    "id": "",
    "value": "PRIME_ANCIENNETE",
    "label": "Prime d'ancienneté",
    "is_automatic": false,
  };