import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import "./ModalStyle.scss";
import { Step, Stepper } from "react-mui-stepper";
import { Breakpoint } from "@mui/system";
import Button from "@mui/material/Button";
import { CloseRounded } from "@mui/icons-material";

export type ModalFormProps = {
  open: boolean;
  modalTitle?: string;
  onModalClose: () => void;
  children?: React.ReactNode;
  className?: string;
  hasStepForm?: boolean;
  steps?: string[];
  stepNumber?: number;
  currentStep?: number;
  onPrevStep?: () => void;
  onNextStep?: () => void;
  modalWidth?: Breakpoint;
  formSubmit?: (data: any) => void;
  cancelButtonLabel?: string | React.ReactNode;
  submitButtonLabel?: string | React.ReactNode;
  submitButtonProps?: any;
  cancelButtonProps?: any;
  withFooter?: boolean
};

export default function ModalForm({
  open,
  modalTitle,
  onModalClose,
  children,
  className,
  hasStepForm = false,
  steps = [],
  stepNumber = 0,
  currentStep = 0,
  onPrevStep,
  onNextStep,
  modalWidth,
  formSubmit,
  cancelButtonLabel = "Annuler",
  submitButtonLabel = "Enregistrer",
  submitButtonProps,
  cancelButtonProps,
  withFooter = true
}: ModalFormProps) {
  let modalFooter;

  if (hasStepForm) {
    modalFooter = (
      <>
        <div className="bg-gray-50 py-5 px-9">
          <div className="w--fit ml-auto flex space-x-3">
            {currentStep === 0 && (
              <Button
                variant="outlined"
                onClick={onModalClose}>
                Annuler
              </Button>
            )}
            {currentStep > 0 && (
              <Button variant="outlined" onClick={onPrevStep}>
                Precedent
              </Button>
            )}
            {currentStep < stepNumber && (
              <Button variant="contained" onClick={onNextStep}>Suivant</Button>
            )}
            {currentStep === stepNumber && (
              <Button variant="contained" className="" onClick={formSubmit}>
                Enregistrer
              </Button>
            )}
          </div>
        </div>
      </>
    );
  } else {
    modalFooter = (
      <>
        <div className="bg-gray-50 py-5 px-9">
          <div className="w--fit ml-auto flex space-x-3">
            {<Button
              {...cancelButtonProps}
              variant="outlined"
              onClick={onModalClose}
            >
              {cancelButtonLabel}
            </Button>}
            <Button variant="contained" {...submitButtonProps} onClick={formSubmit}>
              {submitButtonLabel}
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onModalClose}
        disableEscapeKeyDown={true}
        className={className}
        fullWidth={true}
        maxWidth={modalWidth ? modalWidth : "md"}>
        <div className="w-full flex items-center py-3 px-6 border-b-2 border-gray-100">
          <p className="text--S4">{modalTitle}</p>
          <button className="ml-auto close-btn p-1" onClick={onModalClose}>
            <CloseRounded />
          </button>
        </div>

        <DialogContent style={{padding: 0}}>
          {hasStepForm && (
              <Stepper withNumbers activeStep={currentStep - 1}>
                {steps.map((label) => (
                    <Step key={label}>{label}</Step>
                ))}
              </Stepper>
          )}
          {children}
        </DialogContent>
        {withFooter && modalFooter}
      </Dialog>
    </div>
  );
}
