import moment from "moment";
import { PaymentsDatesPeriods } from "@/types/SettingsType";

export const getPaymentsDates = (periods: PaymentsDatesPeriods) => {
  const MONTHS = 12;
  return [...Array(MONTHS / periods.frequency).keys()].map((m, index) => {
    let _month = periods.start + m * periods.frequency;
    return `${periods.day}-${_month > MONTHS ? periods.start : _month}`;
  });
};


export const formatNextPaymentDate = (paymentDates: string[]) => {
  const date = determiningNextPaymentDate(paymentDates);
  return moment([moment().year(), date.month - 1, date.day]).format("DD/MM/YYYY");
};

export const determiningNextPaymentDate = (paymentDates: string[]): { month: string, day: string } => {
  const today = { day: moment().date(), month: moment().month() + 1 };
  for (let i = 0; i < paymentDates.length; i++) {
    let isTargetMonth = false;
    let [day, month] = paymentDates[i].split("-");

    if (today.month <= parseInt(month)) {
      if (i === 0) isTargetMonth = true;
      else {
        let [_, prevMonth] = paymentDates[i - 1].split("-");
        if (today.month === parseInt(month) && today.day > parseInt(day)) {
          continue;
        }
        if (parseInt(prevMonth) <= today.month) isTargetMonth = true;
      }

      if (isTargetMonth) {
        return { day, month };
      }
    }
  }
  let [day, month] = paymentDates[0].split("-");
  return { day, month };
};
