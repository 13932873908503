import { Button, ListItemIcon } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ButtonProps } from "@mui/material/Button";
import { HelpOutlineRounded, SupportAgentRounded } from "@mui/icons-material";

const FreshworksButton = (props: ButtonProps) => {
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        if (isWidgetOpen) {
          window.FreshworksWidget("hide");
          setIsWidgetOpen(false);
        }
      }
    };
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isWidgetOpen]);
  
  const toggleFreshdeskWidget = () => {
    if (typeof window.FreshworksWidget !== "undefined") {
      if (isWidgetOpen) {
        window.FreshworksWidget("hide");
      } else {
        window.FreshworksWidget("open");
      }
      setIsWidgetOpen(!isWidgetOpen);
    }
  };
  const menuItemChildren = [
    {
      icon: <HelpOutlineRounded />,
      button: <span
        onClick={toggleFreshdeskWidget}
      >Support en ligne et FAQ</span>,
    },
    {
      icon: <SupportAgentRounded />,
      button: <a href="https://support.uptimise.io/fr/" target="_blank">Besoin d’assistance</a>,
    },
    // {
    //   icon: <CachedRounded />,
    //   button: <span>Nouveautés</span>,
    // },
  ];
  
  
  return (
    <div ref={modalRef} className="relative">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableRipple
        startIcon={<HelpOutlineRounded />}
        onClick={handleClick}
        variant="contained"
        color="primary"
        size="small"
        className="!rounded-l-full !rounded-br-full !rounded-tr-lg !text-sm"
        sx={{
          padding: "6px 10px",
          ".MuiButton-startIcon": {
            marginRight: "2px",
          },
        }}
        {...props}
      >
        Aide
      </Button>
      <Menu
        id="basic-menu"
        /* elevation={1} */
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ borderRadius: 0 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {menuItemChildren.map((item, index) => (
          <MenuItem
            sx={{ px: 2 }}
            disableRipple
            onClick={handleClose}
            className="hover:text-blue-500"
            key={index}
          >
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            {item.button}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FreshworksButton;
