import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type AlertType = {
  type: null | "error" | "warning" | "success" | "info",
  message: string | null,
  is_fired?: boolean,

}

const initialState: { alert: AlertType } = {
  alert: {
    type: null,
    message: null,
  },
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    fireAlert: (state, action: PayloadAction<AlertType>) => {
      state.alert = { ...action.payload, is_fired: true };
    },
    closeAlert: (state) => {
      state.alert = { ...state.alert, is_fired: false };
    },
  },
});

export const { fireAlert, closeAlert } = alertSlice.actions;
export default alertSlice.reducer;
