import React from "react";
import CreatableSelect from "react-select/creatable";
// import { OnChangeValue } from 'react-select';
import Select from "react-select/dist/declarations/src/Select";
import { CircularProgress } from '@mui/material';
import { grey } from '@mui/material/colors';

interface DropdownInputProps extends React.ComponentPropsWithoutRef<any> {
  selectOptions: Array<any>;
  className: string;
  onSelectChange: Function;
  newValueLabel: string; // my custom prop
  isLoading?: boolean
}

const formatLabel = (value: String, newValue: any) => {
  return <p className="">Ajouter {value} "{newValue}"</p>;
};


const customStyles = {
  control: (base: any, state: { isFocused: any; }) => ({
    ...base,
    background: "transparent",
    height: "40px",
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#399AB3" : "#CCE4EA",
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? null : null
    }
  })
};

const NoOptionsMessage = () => {
  return (
    <p className="text--S6 text-center text-gray-400 py-2">-- Pas d'options --</p>

  );
};

const LoadingIndicator  = () => { return <></>}

const LoadingMessage  = () => {
  return (
    <div className="w-full">
      <span className="flex mt-2 justify-center">
        <CircularProgress sx={{width: '100%'}} size={20} style={{'color': grey[500]}} />
      </span>
      <p className="text--caption py-2 font-normal text-center">chargement...</p>
    </div>
  )
}


const DropdownInput = React.forwardRef<Select, DropdownInputProps>(
  (
    { selectOptions, newValueLabel, placeholder, className, onSelectChange, isLoading },
    ref
  ) => (

    <CreatableSelect
      isLoading={isLoading}
      styles={customStyles}
      allowCreateWhileLoading={true}
      ref={ref}
      className={`${className} loader-grey`}
      isClearable
      placeholder={placeholder}
      onChange={(newValue) => onSelectChange(newValue)}
      components={{ LoadingMessage, LoadingIndicator, NoOptionsMessage   }}
      // onInputChange={handleInputChange}
      options={selectOptions}
      formatCreateLabel={(newValue) => formatLabel(newValueLabel, newValue)}
    />
  )
);

export default DropdownInput;
