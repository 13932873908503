import React, {forwardRef} from 'react';
import Autocomplete from '@mui/material/Autocomplete';

interface MuiAutocompleteProps {
    placeholder?: string,
    options: Array<any>,
    onChange?: (value: any | Array<any>) => void,
    defaultValue?: any,
    value?:any,
}


function MuiAutocomplete(
    props: MuiAutocompleteProps,
    ref: React.Ref<HTMLDivElement> | undefined,) {
    const {options, placeholder, onChange, defaultValue, value} = props

    return (
        <Autocomplete
            ref={ref}
            options={options}
            value={value}
            defaultValue={defaultValue}
            noOptionsText="Aucune option trouvée"
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, selected) => option.label === selected.label}
            onChange={(event, selected) => onChange?.(selected)}
            sx={{
                "&.Mui-focused": {
                    borderWidth: "0px !important"
                },
                "&.MuiAutocomplete-popper &.MuiAutocomplete-option[aria-selected ='true']": {
                    backgroundColor: '#fafafa',
                    fontWeight: 600,
                    borderWidth: '0px !important'
                },
                "&.MuiAutocomplete-popper": {
                    marginTop: '4px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
                }

            }}
            renderInput={(params) => (
                <div ref={params.InputProps.ref}>
                    <input type="text" placeholder={placeholder} {...params.inputProps}
                           className='form--custom w-full'/>
                </div>
            )}
        />
    )
}

export default forwardRef(MuiAutocomplete);
