import React from "react";

interface AdvancedBadgeProps {
  children?: React.ReactNode;
  className?: string;
}
export default function AdvancedBadge({
  children,
  className,
}: AdvancedBadgeProps) {
  return (
    <div className={`${className} flex flex-col justify-center items-center`}>
      {children}
    </div>
  );
}
