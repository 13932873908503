import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Theme from "@/Theme";
import _ from "lodash";

const CollaboListSkeleton = () => {
  return (
    <>
      {
        _.range(8).map((i: number) => (
          <div
            className={`flex items-center w-full mx-auto rounded my-1 px-2 py-1`} key={i}
          >

            <Skeleton variant="circular" width={42} height={42} sx={{ bgcolor: Theme.palette.primary.light }} />
            <span className="ml-2 text-left w-[80%]">
              <Skeleton variant="text" height={12} sx={{
                width: "50%",
                bgcolor: Theme.palette.primary.contrastText,
                marginTop: "4px",
                marginBottom: "8px",
              }} />
              <Skeleton variant="text" height={16}
                        sx={{ width: "65%", bgcolor: Theme.palette.primary.light, marginBottom: "4px" }} />
            </span>
          </div>
        ))
      }
    </>
  );
};

export default CollaboListSkeleton;