import React, {useState} from "react"
import Avatar from "comp_common/avatar/Avatar";
import {
    Divider,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Popover,
    Typography
} from "@mui/material";
import {MoreVert} from "@mui/icons-material";
import {User} from "@/types/UserType";
import {useAppDispatch, useAppSelector} from "@/hooks/reduxToolkitHook";
import {useHistory} from "react-router";
import {http} from "@/utils/http";
import {DELETE_NOTIFICATION_URL, UPDATE_NOTIFICATION_URL} from "@/constants/endpoints";
import {timeElapsed} from "pag_collabo/notifications/utils";
import {deleteNotificationStatus, updateNotification} from "@/redux/features/usersSlice";
import EndContractForm from "pag_collabo/collabo-details/collabo-menu/contrat/EndContractForm";
import Button from "@mui/material/Button";

export enum NotificationEvent  {
    "endContract" = "Fin de contrat",
    "endTrial" = "Fin de période d'essai",
    "endInterShip" = "Fin de stage"
}

export type NotificationItemType = {
    id: string;
    name: string;
    user: string;
    end_date: Date;
    is_read: boolean;
    created_at?: any;
}

export type NotificationProp = {
    notification: NotificationItemType
}

export const NotificationItem = ({notification}: NotificationProp) => {
    const {user, name, end_date, is_read} = notification;
    const {users} = useAppSelector(state => state.users);
    const selectedUser = users.find(u => user == u.id);
    if (!selectedUser){
        return <></>
    }
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [notificationStatus, setNotificationStatus] = useState(true);
    const [openEndUserContractModal, setOpenEndUserContractModal] = useState(false);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {

        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useAppDispatch();

    const open = Boolean(anchorEl);
    const id = open ? 'notif-detail' : undefined;
    const updateNotificationStatus = () => {
        http.patch(UPDATE_NOTIFICATION_URL.replace(":id", notification.id), {"status": notificationStatus}).then(
            () => dispatch(updateNotification(notification.id))
        ).catch(
            (e) => {
                console.log(e)}
        )
    }

    const deleteNotification = () => {
        http.delete(DELETE_NOTIFICATION_URL.replace(":id", notification.id)).then(
            () => dispatch(deleteNotificationStatus(notification.id))
        ).catch(
            (e) => {
                console.log(e)}
        )
    }

    const handleBtnClick = () => {
        updateNotificationStatus();
        history.push(`/ajout-collaborateur/${selectedUser!.id}/contrat/duree`);
    }
    const getStringDate = (date: Date) => {
        const dateObject = new Date(date);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return dateObject.toLocaleTimeString("fr-FR", options).split("à")[0];
    }
    const getCustomMessage = (user: User, name: string) => {
        if (name == NotificationEvent.endContract){
            return  `Le contrat de ${selectedUser!.first_name} ${selectedUser!.last_name} expire le ${getStringDate(end_date)}`
        }
        if (name == NotificationEvent.endInterShip){
            return  `Le stage de ${selectedUser!.first_name} ${selectedUser!.last_name} prend fin le ${getStringDate(end_date)}`
        }
        if (name == NotificationEvent.endTrial){
            return  `La période d'essai de  ${selectedUser!.first_name} ${selectedUser!.last_name} prend fin le ${getStringDate(end_date)}`
        }
        return ``
    }
    return (
        <>
            <div className={!is_read ? "bg-blue-100": ""}>
                <ListItem alignItems="flex-start">

                    <ListItemAvatar>
                        <div className="flex items-center">
                            {!is_read ? <div className="w-2 h-2 bg-blue-500 rounded-full mr-2" ></div> : <div className="w-2 h-2 mr-2" ></div>}
                            <Avatar user={selectedUser}/>
                        </div>

                    </ListItemAvatar>
                    <ListItemText className="ml-2 !mb-0 !pb-0"
                                  primary={<div className="!font-bold">{name}</div>}
                                  secondary={<React.Fragment>
                                      <Typography
                                          component="span"
                                          variant="caption"
                                          // className={classes.inline}
                                          color="textPrimary"
                                          className="font-bold !leading-3"
                                      >
                                          {getCustomMessage(selectedUser, name)}
                                          {/*{`Le contract de ${selectedUser!.first_name} ${selectedUser!.last_name} expire le ${getStringDate(end_date)}`}*/}
                                      </Typography>
                                  </React.Fragment>}/>
                    <ListItemButton className="w-1/5 hover:!bg-transparent">
                        <IconButton aria-label="options" className=""
                                    onClick={handleClick}
                        >
                            <MoreVert/>
                        </IconButton>
                    </ListItemButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div onClick={updateNotificationStatus}>
                            <Typography sx={{ p: 2 }} className="cursor-pointer hover:bg-blue-50">{is_read ? "Marquer comme non lu": "Marquer comme lu"}</Typography>
                        </div>
                        <div onClick={deleteNotification}>
                            <Typography sx={{ p: 2 }} className="cursor-pointer hover:bg-blue-50">Supprimer</Typography>
                        </div>
                    </Popover>
                </ListItem>
                <div className="flex gap-2 ml-[80px]">
                    {
                        name !== "Fin de période d'essai" &&
                        <Button
                            variant="contained"
                            onClick={handleBtnClick}
                        >
                            <span className="text-[12px]">Prolonger</span>
                        </Button>
                    }
                    <Button
                        variant="outlined"
                        onClick={() => setOpenEndUserContractModal(true)}
                    >
                        <span className="text-[12px]">Mettre fin au contrat</span>
                    </Button>
                </div>
                <div className="ml-[75px]  text--caption mb-3 mt-3">
                    Il y a {timeElapsed(notification.created_at)}
                </div>
                <Divider style={{backgroundColor: "#E8F4F8"}} className="mb-5"/>
            </div>
            <EndContractForm open={openEndUserContractModal} onModalClose={() => setOpenEndUserContractModal(false)}
                             userContractType={selectedUser?.contract?.kind} userId={user} />
        </>

    )
}