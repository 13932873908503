import React from 'react'

export interface ILegend { label: string, color: string }

export default function ChartLegend({ items }: { items?: ILegend[] }) {
  return (
    <div className='px-12 mt-8'>
      {items &&
        <>
          <h3 className='text--S5 text-gray-500 mb-3'>Légende</h3>
          <div className="flex gap-x-4">
            {items.map((item: ILegend, i: number) => (
              <div key={i} className="flex gap-x-2">
                <span className="h-4 w-4 rounded" style={{ backgroundColor: item.color }}></span>
                <p className='text-gray-500'>{item.label} </p>
              </div>
            ))}

          </div>
        </>
        }

    </div>
  )
}
