import React from "react";
import PropTypes from "prop-types";

const FileItem = ({ className, file, onFileDelete }) => {
  return (
    <span
      className={`${className} my-3 p-4 bg-blue-300 rounded flex items-center`}
    >
      <i className="ut-description ut--lg text-blue-500 mx-2" />
      <p className="text-blue-500 text-sous-menu ">{file.name} ({file.sizeReadable})</p>
      <i
        className="ut-delete ut--lg text-blue-500 ml-auto mr-1  cursor-pointer"
        onClick={onFileDelete}
      />
    </span>
  );
};

export default FileItem;

FileItem.propTypes = {
  // file: PropTypes.object.isRequired, // un object de type File
  className: PropTypes.string,
  file: PropTypes.instanceOf(File).isRequired, // un object de type File
  onFileDelete: PropTypes.func, // methode pour supprimer un fichier
};
