import React, { useEffect, useState } from "react";
import { Dropdown } from "@/components/common";
import DashboardChart from "@/components/common/chart/DashboardChart";
import {
  getEffectifCollabo,
  getPyramideAgeData
} from "@/utils/utils";
import { useAppSelector } from "@/hooks/reduxToolkitHook";
import ChartLegend, { ILegend } from "../ChartLegend";

type ChartValueType = {
  color: string[];
  max?: number;
  data: any[];
  step: number;
  option?: string;
  legend?: ILegend[],
  barSize?: number
};

const selectOptions = [
  { value: "last-three-months", label: "Les 03 derniers mois", monthsNumber: 3 },
  { value: "last-six-months", label: "Les 06 derniers mois", monthsNumber: 6 },
];


const Reports = () => {
  const { users } = useAppSelector(state => state.users);

  const [usersAgeOptions, setUsersAgeOptions] = useState<ChartValueType>({
    ...getPyramideAgeData(users),
    step: 5,
    option: "Pyramide des âges",
    legend: [{ label: "Nombre de collaborateurs", color: "#6bd8d5" }],
    barSize: 16,
  });
  const [usersNumberOptions, setUsersNumberOptions] = useState<ChartValueType>({
    ...getEffectifCollabo(users, 3),
    step: 5,
    option: "Évolution du nombre des collaborateurs",
    legend: [{ label: "Nombre d'homme", color: "#6bd8d5" }, {
      label: "Nombre de femme",
      color: "#f340b5",
    }, { label: "Effectif total", color: "#399AB3" }],
    barSize: 16,
  });

  useEffect(() => {
   if(usersNumberOptions) console.log("usersNumberOptions", usersNumberOptions);

  }, [usersNumberOptions]);

  const handleDropdownChange = (v: any) => {
    const monthsNumber =
      selectOptions[
        selectOptions.findIndex((elt) => elt.value === v.target.value)
        ].monthsNumber;

    setUsersNumberOptions(
      {
        ...usersNumberOptions,
        ...getEffectifCollabo(users, monthsNumber),
      },
    );
  };

  return (
    <div className="m-10">
      <h1 className="text--titlePage mb-6">Rapports</h1>
      <div className="grid grid-cols-12 overflow-y-auto gap-5">
          <div className="col-span-6 card">
            <p className="text-base font-bold text-gray-700">{usersAgeOptions.option}</p>
            <hr className="my-4 h-[2px] bg-gray-100" />
            <div className="chart mt-8">
              <DashboardChart
                hasToolTip={true}
                chartData={usersAgeOptions?.data}
                yaProps={{ tickCount: usersAgeOptions.step }}
                bProps={{ colors: usersAgeOptions.color, barSize: usersAgeOptions.barSize }}
              />
              <ChartLegend items={usersAgeOptions.legend} />
            </div>
          </div>

          <div className="col-span-6 card">
            <div className="flex flex-wrap items-center justify-between gap-6 mb-5">
              <p className="text-base font-bold text-gray-700">{usersNumberOptions.option}</p>
              <div className="flex lg:justify-end h-[100]">
                <Dropdown
                  placeholder="Filtres"
                  options={selectOptions}
                  onChange={handleDropdownChange}
                />
              </div>
            </div>
            <hr className="my-4 h-[2px] bg-gray-100" />
            <div className="chart mt-8">
              <DashboardChart
                hasToolTip={true}
                chartData={usersNumberOptions.data}
                yaProps={{ tickCount: usersNumberOptions.step }}
                bProps={{ colors: usersNumberOptions.color, barSize: usersNumberOptions.barSize }}
              />
              <ChartLegend items={usersNumberOptions.legend} />
            </div>
          </div>
      </div>
    </div>
  );
};

export default Reports;
