import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserFullName, getUserPositionInCompany, isUserRegistrationCompleted } from "@/utils/utils";
import "./styles.scss";
import { User } from "@/types/UserType";
import Avatar from "comp_common/avatar/Avatar";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { Button } from "components/common";
import { Users } from "@/requests/users";
import { useAppDispatch } from "@/hooks/reduxToolkitHook";
import { deleteSelectedUser } from "@/redux/features/usersSlice";
import { KEYCLOAK_ACTIONS_TASK_STATUS_URL } from "@/constants/endpoints";
import keycloak from "@/utils/keycloakUtils";
import useWebSocket from "react-use-websocket";
import bifrostKcActionReturnHandler from "pag_collabo/settings/accessManagement/utils/utils";
import { fireAlert } from "@/redux/features/alertsSlice";

export type CollaboItemProps = { className: string, avatar?: string, collabo: User, isSelected?: boolean }

export default function CollaboItem({ className, avatar, collabo, isSelected }: CollaboItemProps) {
  const history = useHistory();
  const collaboFullName = getUserFullName(collabo, true);
  const isProfileComplete = collabo.is_active ?? isUserRegistrationCompleted(
    collabo.enrollment_status,
    collabo.is_old,
  );
  const [hover, setHover] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [socketIsOpened, setSocketIsOpened] = useState<boolean>(false);
  const [deleteUserTaskId, setDeleteUserTaskId] = useState<string | null | undefined>(null);

  const dispatch = useAppDispatch();

  function goToDetails() {
    if (!isProfileComplete) {
      history.push(`/ajout-collaborateur/${collabo.id}/processus`);
    } else {
      history.push({
        pathname: `/collaborateurs/${collabo.id}/profil`,
        state: collabo,
      });
    }
  }

  const deleteUserRequest = async (collabo: User) => {
    Users.deleteUser(collabo.id)
      .then(({ data }) => {
        if ("task_id" in data) {
          const { task_id: taskId } = data;
          setDeleteUserTaskId(taskId);
          setSocketIsOpened(true);
          setConfirmOpen(false);
        } else {
          dispatch(fireAlert({ type: "success", message: data.message }));
        }
        dispatch(deleteSelectedUser(collabo.id));
      })
      .catch(({ data: { data } }) => {
        dispatch(fireAlert({ type: "error", message: data.message }));
      });
  };


  const socketUrl = KEYCLOAK_ACTIONS_TASK_STATUS_URL.replace(":task_id", deleteUserTaskId!) + `?key=${keycloak.token}`;
  useWebSocket(
    socketUrl,
    {
      share: true,
      onOpen: () => {
        console.log(`Connection opened to ${socketUrl}`);
      },
      onMessage: (e) => {
        const data = JSON.parse(e.data);
        const { kcActionFinished, returnedData } = bifrostKcActionReturnHandler(
          data,
          "KEYCLOAK_ACTIONS_FINISHED",
          "KEYCLOAK_ACTIONS_FAILED",
          data.message || "La suppression est terminée",
          data.message || "La suppression a échoué",
          () => {
            setSocketIsOpened(false);
            setDeleteUserTaskId(null);
          },
          dispatch,
        );
        if (kcActionFinished && returnedData) {
          dispatch(deleteSelectedUser(collabo.id));
        }
      },
      onClose: () => {
        console.log(`Connection close to ${socketUrl}`);
        setSocketIsOpened(false);
        setDeleteUserTaskId(null);
      },
      shouldReconnect: () => true,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
      reconnectAttempts: 5,
    },
    socketIsOpened,
  );


  return (
    <div
      className={`flex items-center w-full my-2 mx-auto ${className} hover:bg-blue-50 rounded p-2 cursor-pointer ${isSelected ? "!bg-blue-100" : ""} `}
      onClick={goToDetails}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Avatar user={collabo} />
      <span className="ml-2 text-left truncate">
        <h4 className="word--wrap text--button text-gray-600">
          {collaboFullName}
        </h4>
        <p className="w-[12rem] text--caption text-gray-600 mb-1 word--wrap truncate">
          {getUserPositionInCompany(collabo)}
        </p>
        {!isProfileComplete && (
          <span className="py-[2px] px-2 rounded text-white bg-orange-500 text--caption !font-medium">
            En cours
          </span>
        )}
      </span>

      <ConfirmForm handleClose={(v) => setConfirmOpen(!v)}
                   handleSubmit={() => deleteUserRequest(collabo)}
                   confirmationText="Supprimer"
                   isOpen={confirmOpen}
                   collabo={collabo}
      />

      {hover && (
        <div className="ml-auto bg-red-200 text-center p-1" style={{ width: "32px", borderRadius: "16px" }}>
          <DeleteIcon className="text-red-400 bg-red-200" style={{ width: "16px" }}
                      onClick={() => {
                        setConfirmOpen(!confirmOpen);
                        setHover(false);
                      }} />
        </div>
      )}

    </div>
  );
}

type ConfirmFormPros = {
  isOpen: boolean,
  handleClose: (v: boolean) => void,
  handleSubmit: (v: any) => void,
  confirmationText: string, collabo: User,
}

export const ConfirmForm = ({
                              isOpen,
                              handleSubmit,
                              handleClose,
                              confirmationText,
                              collabo,
                            }: ConfirmFormPros) => {
  const handlePaste = (e: any) => {
    e.preventDefault();
  };
  const [value, setValue] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const collaboFullName = getUserFullName(collabo, true);

  useEffect(() => {
    setValue("");
    setIsTouched(false);
  }, [isOpen]);

  return (
    <div>
      <Dialog open={isOpen} onClose={handleClose}>
        <div className="w-full flex items-center py-3 px-6 border-b-2 border-gray-100">
          <span className="font-bold">Suppression de collaborateur</span>
            <button
            className="ml-auto close-btn py-1"
            onClick={(_e) => {
              handleClose(true);
              setValue("");
              setIsTouched(false);
            }}
          >
            <i className="ut-clear text-blue-[#575757] p-2 rounded-2xl" />
          </button>
        </div>
        <DialogContent>
          <div>
            <h1>
              Voulez - vous vraiment
              supprimer le collaborateur
              <strong> {collaboFullName} </strong>
            </h1>
            <br />
            <h2> Cette opération est irréversible. Si vous supprimez {collaboFullName},
              vous perdrez toutes les donneés qui y sont liées.</h2>
          </div>
          <div className="flex flex-col">
            <label className="text--submenu--noActif my-5">
              {`Veuillez saisir le nom "Supprimer ${collabo?.usual_name}" pour confirmer la suppression.`}
            </label>
            <input
              autoComplete="off"
              onPaste={handlePaste}
              value={value}
              className={`form--custom w-full mt-2 ${
                isTouched && value !== collabo?.last_name ? "!border-red-400" : ""
              }`}
              placeholder="Nom du collaborateur"
              onChange={(e) => {
                setIsTouched(true);
                setValue(e.target.value);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions className="flex justify-end space-x-4">
          <Button
            buttonSize="btn--medium"
            buttonStyle="btn--danger"
            isDisabled={value !== "Supprimer " + collabo?.usual_name}
            onClick={handleSubmit}
          >
            {confirmationText}
          </Button>
          <Button
            buttonSize="btn--medium"
            onClick={(_e) => {
              handleClose(true);
              setValue("");
              setIsTouched(false);
            }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

