import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {http} from "@/utils/http";
import {AxiosResponse, CustomError, ErrorResponse} from "@/types/api/AxiosType";
import {FETCH_PATCH_DELETE_TEAMS_URL} from "@/constants/endpoints";
import {AxiosError} from "axios";
import {TeamState, TeamType, TeamUser} from "@/types/TeamsType";


export const fetchTeams = createAsyncThunk("GET_TEAMS", async (_, thunkApi) => {
    return http
        .get<AxiosResponse<TeamType[]>>(FETCH_PATCH_DELETE_TEAMS_URL)
        .then(res => res.data)
        .catch(e => {
            const error = e as AxiosError<CustomError>;
            const response = error.response as unknown as AxiosResponse<ErrorResponse>;
            return thunkApi.rejectWithValue({error: response.data});
        });
});

export const fetchTeamByID = createAsyncThunk("GET_TEAM_BY_ID", async (id: string, thunkApi) => {
    return http
        .get<AxiosResponse<TeamType>>(`${FETCH_PATCH_DELETE_TEAMS_URL + id}/`)
        .then(res => res.data)
        .catch(e => {
            const error = e as AxiosError<CustomError>;
            const response = error.response as unknown as AxiosResponse<ErrorResponse>;
            return thunkApi.rejectWithValue({error: response.data});
        });
});


const initialState: TeamState = {
    teams: [],
    teamStatus: 'idle',
    error: null,
    selectedTeam: undefined
}

const teamsSlice = createSlice({
    name: "teams",
    initialState,
    reducers: {
        createTeam: (state, action: PayloadAction<AxiosResponse<TeamType>>) => {
            state.teams = [...state.teams, action.payload.data]
        },
        updateTeam: (state, action: PayloadAction<AxiosResponse<TeamType>>) => {
            // state.selectedTeam = action.payload.data
            state.teams = state.teams.map((item, _) => {
                if (item.id === action.payload.data.id) {
                    return action.payload.data
                }
                return item
            })
        },
        bulkTeamsUpdate: (state, action: PayloadAction<AxiosResponse<TeamType[]>>) => {
            state.teams = state.teams.map((item, _) => {
                const foundTeam = action.payload.data.find(t => t.id === item.id)
                return foundTeam ? foundTeam : item
            })
        },
        addMembersToTeam: (state, action: PayloadAction<AxiosResponse<Array<TeamUser>>>) => {
            const members = action.payload.data.map(item => item.user)
            const team = action.payload.data[0].team
            state.teams = state.teams.map((item, _) => {
                if (item.id === team) {
                    item.members = [...item.members, ...members]
                    return item
                }
                return item
            })
            state.selectedTeam = state.teams.find(item => item.id === team)
        },

        deleteTeam: (state, action: PayloadAction<AxiosResponse<{ id: string }>>) => {
            state.teams = state.teams.filter((item, _) => item.id !== action.payload.data.id)
        },
        removeUserFromTeam: (state, action: PayloadAction<AxiosResponse<TeamType>>) => {
            const teamId = action.payload.data.id;
            state.teams = state.teams.map((item, _) => {
                if (item.id === teamId) {
                    item.members = action.payload.data.members;
                    return item;
                }
                return item;
            });
            state.selectedTeam = state.teams.find(item => item.id === teamId);
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchTeams.pending, (state) => {
                state.teamStatus = "loading";
            })
            .addCase(fetchTeams.fulfilled, (state, action) => {
                state.teamStatus = "succeeded";
                state.teams = action.payload.data;
            })
            .addCase(fetchTeams.rejected, (state, action) => {
                state.teamStatus = "failed";
                state.error = action.error;
            });

        builder
            .addCase(fetchTeamByID.pending, (state) => {
                state.teamStatus = "loading";
            })
            .addCase(fetchTeamByID.fulfilled, (state, action) => {
                state.teamStatus = "succeeded";
                state.selectedTeam = action.payload.data;
            })
            .addCase(fetchTeamByID.rejected, (state, action) => {
                state.teamStatus = "failed";
                state.error = action.error;
            });
    }
})

export const {addMembersToTeam, deleteTeam, createTeam, updateTeam, bulkTeamsUpdate, removeUserFromTeam} = teamsSlice.actions
export default teamsSlice.reducer
