import React from "react";
import PropTypes from "prop-types";
import "./SearchBarWithSelectBox.scss";

function SearchBarWithSelectBox({
  placeholder,
  selectName,
  selectOptions,
  defaultOption,
  className,
}) {
  return (
    <div className={`${className} form-control w-[28em]`}>
      <div className="drop-searchbar h-[38px] text-xs rounded-md bg-gray-50 border-custom-l3 border-2 focus-within:ring-custom-l2 focus-within:border-blue-500">
        {selectOptions && (
          <select
            className="ml-[18px] word--wrap w--fit"
            name={selectName}
            id={selectName}
            defaultValue={defaultOption}
          >
            {selectOptions.map((option) => (
              <option
                key={option.value}
                value={option.value}
                disabled={option.disabled}
                defaultChecked={option.selected}
                hidden={option.hidden}
              >
                {option.text}
              </option>
            ))}
          </select>
        )}
        <div className="h-[60%] my-auto mx-2 w-[2px] bg-gray-300" />
        <input
          type="search"
          name="search-bar"
          className=" appearance-none text-gray-800 group-focus:ring-base-300 w-full bg-transparent outline-none rounded-md pl-2 py-[6px]"
          placeholder={placeholder}
        />
        <div className="cursor-pointer">
          <i className="ut-search text-[#151515] ut--flip-x mr-2" />
        </div>
      </div>
    </div>
  );
}

SearchBarWithSelectBox.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectName: PropTypes.string,
  selectOptions: PropTypes.array,
  defaultOption: PropTypes.string,
  className: PropTypes.string,
};

export default SearchBarWithSelectBox;
