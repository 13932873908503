import { Redirect } from "react-router-dom";
import React from "react";
import { useAppSelector } from "@/hooks/reduxToolkitHook";
import { CircularProgress } from "@mui/material";

export const AppGuard = ({
                           children,
                         }: { children: React.ReactNode }) => {
  const { paysheetsGenerationJob } = useAppSelector(state => state.paysheets);

  const paysheetGenerationIsPending = paysheetsGenerationJob && paysheetsGenerationJob.status == "pending";

  if (!paysheetsGenerationJob) {
    return (
      <div className="flex items-center justify-center h-screen"><CircularProgress /></div>
    );
  }
  if (paysheetGenerationIsPending) {
    return <Redirect push to="/monthly-payslip-pending" />;
  }
  return <>{children}</>;
};
