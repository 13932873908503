import React, {ReactNode} from "react";
import {Link} from "react-router-dom";

export type FormInfoBluePropsType = {
    className?: string,
    infoText?: ReactNode,
    infoList?: string[],
    link?: string
    type?: 'info' | 'warning' | 'danger' | 'notice'
}

function FormInfoBlue({className, infoText, infoList, type = 'info', link = ''}: FormInfoBluePropsType) {

    function getTypeStyle(type: string) {
        switch (type) {
            case 'info':
                return { icon: 'ut-info text-blue-600', class: 'bg-blue-100 border-blue-500 ' };

            case 'warning':
                return { icon: 'ut-info text-orange-400', class: 'bg-red-50 border-orange-400 ' }

            case 'danger':
                return { icon: 'ut-warning-amber-ol text-red-600', class: 'bg-red-50 border-red-500 ' }

            case 'notice':
                return {icon: 'ut-lightbulb text-blue-600', class: 'bg-white border-blue-500 '};

            default:
                return { icon: 'ut-info text-blue-600', class: 'bg-blue-100 border-blue-500 ' };
        }
    }

    return (
        <div className={`${getTypeStyle(type)?.class} border border-solid p-4 rounded flex gap-3 text-xs ${className}`}>
            <i className={`mt-1 ut--lg ${getTypeStyle(type)?.icon}`} />
            <div>
                <p className="">{infoText}</p>
                <ul className="list-inside">
                    {infoList &&
                    infoList.map((infoList, i) => (
                        <li className="ml-1 mt-" key={i}>
                            {infoList}
                        </li>
                    ))}
                </ul>
                {link &&
                <Link className="cursor-pointer" to={link}>
                    En savoir plus
                </Link>
                }

            </div>
        </div>
    );
}

export default FormInfoBlue;
