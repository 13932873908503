import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const bottomRight = { vertical: "bottom", horizontal: "right" };

const AlertBox = ({
                        type,
                        sx = { width: "100%" },
                        open,
                        onClose,
                        autoHideDuration = 6000,
                        message,
                        position = bottomRight,
                      }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      anchorOrigin={position}
      onClose={onClose}
    >
      <Alert
        severity={type}
        sx={sx}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBox;
