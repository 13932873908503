import {http} from "@/utils/http";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ADD_FETCH_ACOMPTES_USERS_URL,
    ADD_FETCH_REIMBURSEMENT_SALARY_ADVANCE_URL,
    ADD_FETCH_SALARY_ADVANCES_USERS_URL,
    FETCH_ALL_REIMBURSEMENTS_URL,
    FETCH_ALL_SALARY_ADVANCE_URL,
    FETCH_USER_WITH_SALARY_ADVANCES_URL,
} from "@/constants/endpoints";
import {AxiosResponse} from "@/types/api/AxiosType";
import {
    AcompteType,
    ReimbursementsType,
    SalaryAdvancesType,
    UserWithSalaryAdvance,
} from "@/types/api/UserSalaryAdvancesType";
import {getUserAcomptes, getUsersAcomptes} from "@/requests/api";


// fetch salary advance for specifc user
export const fetchSalaryAdvanceByUser = createAsyncThunk(
    "GET_USERS/:id/SALARY_ADVANCES", async (args: { id: string, params?: { month?: string | number, year?: string | number } }, thunkApi) => {
        return http
            .get<AxiosResponse<SalaryAdvancesType[]>>(ADD_FETCH_SALARY_ADVANCES_USERS_URL.replace(":id", `${args.id}`), {params: args.params})
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                //     const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({error: e.data});
            });
    });

// fetch  all salary advances
export const fetchSalaryAdvance = createAsyncThunk(
    "GET_SALARY_ADVANCES", async (_, thunkApi) => {
        return http
            .get<AxiosResponse<SalaryAdvancesType[]>>(FETCH_ALL_SALARY_ADVANCE_URL)
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    });


// acomptes
export const fetchAcompte = createAsyncThunk(
    "GET_ACOMPTES", async (args: { params?: { month: string } }, thunkApi) => {
        return getUsersAcomptes(args.params).then(data =>
            data
        ).catch(e => {
            return thunkApi.rejectWithValue({data: e.data});
        })
    });

export const fetchUserAcomptes = createAsyncThunk(
    "GET_USER_ACOMPTES", async (args: { id: string, params?: { month: string } }, thunkApi) => {
        return getUserAcomptes(args.id, args.params)
            .then(data => data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    });

// fetch  all reimbursements
export const fetchReimbursements = createAsyncThunk(
    "GET_REIMBURSEMENTS", async (_, thunkApi) => {
        return http
            .get<AxiosResponse<ReimbursementsType[]>>(FETCH_ALL_REIMBURSEMENTS_URL)
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    });


// fetch user with salary advance
export const fetchUserWithSalaryAdvances = createAsyncThunk("GET_USERS_WITH_SALARY_ADVANCE", async (params: { month?: string | number, year?: string | number } = {}, thunkApi) => {
    return http
        .get<AxiosResponse<UserWithSalaryAdvance[]>>(FETCH_USER_WITH_SALARY_ADVANCES_URL, {params})
        .then(res => res.data)
        .catch(e => {
            // const error = e as AxiosError<CustomError>;
            // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
            return thunkApi.rejectWithValue({data: e.data});
        });
});

export const createSalaryAdvance = createAsyncThunk<AxiosResponse<SalaryAdvancesType>, SalaryAdvancesType>(
    "CREATE_SALARY_ADVANCES",
    async (advance: Partial<SalaryAdvancesType>, thunkApi) => {
        return http
            .post<AxiosResponse<SalaryAdvancesType>>(ADD_FETCH_SALARY_ADVANCES_USERS_URL.replace(":id", `${advance.user}`), advance)
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

export const createAcompte = createAsyncThunk<AxiosResponse<AcompteType>, AcompteType>(
    "CREATE_SALARY_ADVANCES",
    async (advance: Partial<AcompteType>, thunkApi) => {
        return http
            .post<AxiosResponse<AcompteType>>(ADD_FETCH_ACOMPTES_USERS_URL.replace(":id", `${advance.user}`), advance)
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

// add reimbursement for specific salary advance
export const createReimbursement = createAsyncThunk<AxiosResponse<ReimbursementsType>, ReimbursementsType>(
    "CREATE_REIMBURSEMENT",
    async (reimbursement: Partial<ReimbursementsType>, thunkApi) => {
        return http
            .post<AxiosResponse<ReimbursementsType>>(ADD_FETCH_REIMBURSEMENT_SALARY_ADVANCE_URL
                    .replace(":id", `${reimbursement.user}`)
                    .replace(":advance_id", `${reimbursement.salary_advance}`)
                , reimbursement)
            .then(res => res.data)
            .catch(e => {
                // const error = e as AxiosError<CustomError>;
                // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
                return thunkApi.rejectWithValue({data: e.data});
            });
    },
);

// export const updateSalaryAdvance = createAsyncThunk<AxiosResponse<SalaryAdvancesType>, string>(
//     "UPDATE_SALARY_ADVANCE",
//     async (id: string, thunkApi) => {
//         return http
//             .patch<AxiosResponse<SalaryAdvancesType>>(FETCH_PATCH_DELETE_SALARY_ADVANCE_URL.replace(":id", id))
//             .then(res => res.data)
//             .catch(e => {
//                 const error = e as AxiosError<CustomError>;
//                 const response = error.response as unknown as AxiosResponse<ErrorResponse>;
//                 return thunkApi.rejectWithValue({error: response.data});
//             });
//     },
// );
//
//
// export const deleteSalaryAdvance = createAsyncThunk<AxiosResponse<SalaryAdvancesType>, string>(
//     "DELETE_SALARY_ADVANCE",
//     async (id: string, thunkApi) => {
//         return http
//             .delete<AxiosResponse<SalaryAdvancesType>>(FETCH_PATCH_DELETE_SALARY_ADVANCE_URL.replace(":id", id))
//             .then(res => res.data)
//             .catch(e => {
//                 const error = e as AxiosError<CustomError>;
//                 const response = error.response as unknown as AxiosResponse<ErrorResponse>;
//                 return thunkApi.rejectWithValue({error: response.data});
//             });
//     },
// );


// initial state
const initialState: {
    // user with salary advance
    usersWithSalaryAdvance: UserWithSalaryAdvance[],
    fetchUserWithSalaryAdvanceStatus: string,
    fetchUserWithSalaryAdvanceErrors?: any,

    // salary advance
    salaryAdvances: SalaryAdvancesType[],
    fetchSalaryAdvancesStatus: string,
    fetchSalaryAdvancesErrors?: any,

    // acomptes
    acomptes: AcompteType[],
    fetchAcompteStatus: string,
    fetchAcompteErrors?: any,

    // reimbursements
    reimbursements: ReimbursementsType[],
    fetchReimbursementsStatus: string,
    fetchReimbursementsErrors?: any,

    // add salary advance
    addSalaryAdvanceStatus: string,
    addSalaryAdvanceErrors?: any,

    // add acomptes
    addAcomptesStatus: string,
    addAcomptesErrors?: any,

    //add reimbursement
    addReimbursementStatus: string,
    addReimbursementErrors?: any,


    // delete salary advance
    deleteSalaryAdvanceStatus: any,
    deleteSalaryAdvanceErrors?: string,

    // delete reimbursement
    deleteReimbursementStatus: any,
    deleteReimbursementErrors?: string,
} = {
    usersWithSalaryAdvance: [],
    fetchUserWithSalaryAdvanceStatus: "idle",

    salaryAdvances: [],
    fetchSalaryAdvancesStatus: "idle",

    acomptes: [],
    fetchAcompteStatus: "idle",

    reimbursements: [],
    fetchReimbursementsStatus: 'idle',

    addSalaryAdvanceStatus: "idle",
    addReimbursementStatus: "idle",
    addAcomptesStatus: "idle",
    deleteSalaryAdvanceStatus: "idle",
    deleteReimbursementStatus: "idle",
};

const salaryAdvanceSlice = createSlice({
    name: "salaryAdvances",
    initialState,
    reducers: {
        addSalaryAdvance: (state, action: PayloadAction<SalaryAdvancesType>) => {
            state.salaryAdvances = [action.payload, ...state.salaryAdvances];
        },
        deleteSalaryAdvance: (state, action: PayloadAction<string>) => {
            state.reimbursements = state.reimbursements.filter(r => r.user !== action.payload)
            state.salaryAdvances = state.salaryAdvances.filter(advance => advance.id !== action.payload)
        },
        updateSalaryAdvance: (state, action: PayloadAction<SalaryAdvancesType>) => {
            state.salaryAdvances = state.salaryAdvances.filter(advance => advance.id !== action.payload.id)
            state.salaryAdvances = [...state.salaryAdvances, action.payload]
        },

        // acomptes
        addAcompte: (state, action: PayloadAction<AcompteType>) => {
            state.acomptes = [action.payload, ...state.acomptes];
        },
        deleteAcompte: (state, action: PayloadAction<string>) => {
            state.acomptes = state.acomptes.filter(acompte => acompte.id !== action.payload)
        },

        addReimbursement: (state, action: PayloadAction<ReimbursementsType>) => {
            state.reimbursements = [action.payload, ...state.reimbursements];
        },
        updateReimbursement: (state, action: PayloadAction<ReimbursementsType>) => {
            state.reimbursements = [...state.reimbursements, action.payload]
        },
        deleteReimbursement: (state, action: PayloadAction<string>) => {
            state.reimbursements = state.reimbursements.filter(r => r.id !== action.payload)
        },
    },

    extraReducers: function (builder) {
        // get  user with salary advance
        builder
            .addCase(fetchUserWithSalaryAdvances.pending, (state) => {
                state.fetchUserWithSalaryAdvanceStatus = "loading";
            })
            .addCase(fetchUserWithSalaryAdvances.fulfilled, (state, action) => {
                state.fetchUserWithSalaryAdvanceStatus = "succeeded";
                state.usersWithSalaryAdvance = action.payload.data;
            })
            .addCase(fetchUserWithSalaryAdvances.rejected, (state, action) => {
                state.fetchUserWithSalaryAdvanceStatus = "failed";
                state.fetchUserWithSalaryAdvanceErrors = action.error;
            });

        // user all salary advance
        builder
            .addCase(fetchSalaryAdvance.pending, (state) => {
                state.fetchSalaryAdvancesStatus = "loading";
            })
            .addCase(fetchSalaryAdvance.fulfilled, (state, action) => {
                state.fetchSalaryAdvancesStatus = "succeeded";
                state.salaryAdvances = action.payload.data;
            })
            .addCase(fetchSalaryAdvance.rejected, (state, action) => {
                state.fetchSalaryAdvancesStatus = "failed";
                state.fetchSalaryAdvancesErrors = action.error;
            });

        // users acomptes
        builder
            .addCase(fetchAcompte.pending, (state) => {
                state.fetchAcompteStatus = "loading";
            })
            .addCase(fetchAcompte.fulfilled, (state, action) => {
                state.fetchAcompteStatus = "succeeded";
                state.acomptes = action.payload.data;
            })
            .addCase(fetchAcompte.rejected, (state, action) => {
                state.fetchAcompteStatus = "failed";
                state.fetchAcompteErrors = action.error;
            });

        builder
            .addCase(fetchUserAcomptes.pending, (state) => {
                state.fetchAcompteStatus = "loading";
            })
            .addCase(fetchUserAcomptes.fulfilled, (state, action) => {
                state.fetchAcompteStatus = "succeeded";
                state.acomptes = action.payload;
            })
            .addCase(fetchUserAcomptes.rejected, (state, action) => {
                state.fetchAcompteStatus = "failed";
                state.fetchAcompteErrors = action.error;
            });

        // user all reimbursement
        builder
            .addCase(fetchReimbursements.pending, (state) => {
                state.fetchReimbursementsStatus = "loading";
            })
            .addCase(fetchReimbursements.fulfilled, (state, action) => {
                state.fetchReimbursementsStatus = "succeeded";
                state.reimbursements = action.payload.data;
            })
            .addCase(fetchReimbursements.rejected, (state, action) => {
                state.fetchReimbursementsStatus = "failed";
                state.fetchReimbursementsErrors = action.error;
            });

        // create salary advance
        builder
            .addCase(createSalaryAdvance.pending, (state) => {
                state.addSalaryAdvanceStatus = "loading";
            })
            .addCase(createSalaryAdvance.fulfilled, (state, action) => {
                state.addSalaryAdvanceStatus = "succeeded";
                state.salaryAdvances = [...state.salaryAdvances, action.payload.data];

            })
            .addCase(createSalaryAdvance.rejected, (state, action) => {
                state.addSalaryAdvanceStatus = "failed";
                state.addReimbursementErrors = action.error;
            });

        // create salary advance
        builder
            .addCase(createReimbursement.pending, (state) => {
                state.addReimbursementStatus = "loading";
            })
            .addCase(createReimbursement.fulfilled, (state, _action) => {
                state.addReimbursementStatus = "succeeded";
                // state.salaryAdvances = [...state.salaryAdvances, action.payload.data];

            })
            .addCase(createReimbursement.rejected, (state, action) => {
                state.addReimbursementStatus = "failed";
                state.addReimbursementErrors = action.error;
            });
    },
});

export const {
    addReimbursement,
    addSalaryAdvance,
    addAcompte,
    deleteSalaryAdvance,
    deleteAcompte,
    updateSalaryAdvance,
    deleteReimbursement,
    updateReimbursement
} = salaryAdvanceSlice.actions
export default salaryAdvanceSlice.reducer;

