import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

interface DropdownMenuProps {
  buttonChild: React.ReactNode;
  menuItemChildren: React.ReactNode[];
  menu?: Boolean
}

export default function DropdownMenu(props: DropdownMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableRipple
        variant="outlined"
        onClick={handleClick}>
        {props.buttonChild}
      </Button>
      <Menu
        id="basic-menu"
        /* elevation={1} */
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ mt: 1.5, borderRadius: 0 }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {props.menuItemChildren.map((item, index) => (
          <MenuItem
            sx={{ px: props.menu ? 4 : 2 }}
            disableRipple
            onClick={handleClose}
            key={index}>
            {item}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
