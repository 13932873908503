export const Countries = {
  AF: "Afghanistan",
  ZA: "Afrique du Sud",
  AL: "Albanie",
  DZ: "Algérie",
  DE: "Allemagne",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AN: "Antilles néerlandaises",
  SA: "Arabie saoudite",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BR: "Brésil",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  CV: "Cap-Vert",
  EA: "Ceuta et Melilla",
  CL: "Chili",
  CN: "Chine",
  CY: "Chypre",
  CO: "Colombie",
  KM: "Comores",
  CG: "Congo-Brazzaville",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  CR: "Costa Rica",
  CI: "Côte d’Ivoire",
  HR: "Croatie",
  CU: "Cuba",
  DK: "Danemark",
  DG: "Diego Garcia",
  DJ: "Djibouti",
  DM: "Dominique",
  EG: "Égypte",
  SV: "El Salvador",
  AE: "Émirats arabes unis",
  EC: "Équateur",
  ER: "Érythrée",
  ES: "Espagne",
  EE: "Estonie",
  VA: "État de la Cité du Vatican",
  FM: "États fédérés de Micronésie",
  US: "États-Unis",
  ET: "Éthiopie",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  GS: "Géorgie du Sud et les îles Sandwich du Sud",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GD: "Grenade",
  GL: "Groenland",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GQ: "Guinée équatoriale",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  GF: "Guyane française",
  HT: "Haïti",
  HN: "Honduras",
  HU: "Hongrie",
  BV: "Île Bouvet",
  CX: "Île Christmas",
  CP: "Île Clipperton",
  AC: "Île de l'Ascension",
  IM: "Île de Man",
  NF: "Île Norfolk",
  AX: "Îles Åland",
  KY: "Îles Caïmans",
  IC: "Îles Canaries",
  CC: "Îles Cocos - Keeling",
  CK: "Îles Cook",
  FO: "Îles Féroé",
  HM: "Îles Heard et MacDonald",
  FK: "Îles Malouines",
  MP: "Îles Mariannes du Nord",
  MH: "Îles Marshall",
  UM: "Îles Mineures Éloignées des États-Unis",
  SB: "Îles Salomon",
  TC: "Îles Turks et Caïques",
  VG: "Îles Vierges britanniques",
  VI: "Îles Vierges des États-Unis",
  IN: "Inde",
  ID: "Indonésie",
  IQ: "Irak",
  IR: "Iran",
  IE: "Irlande",
  IS: "Islande",
  IL: "Israël",
  IT: "Italie",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KG: "Kirghizistan",
  KI: "Kiribati",
  KW: "Koweït",
  LA: "Laos",
  LS: "Lesotho",
  LV: "Lettonie",
  LB: "Liban",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MK: "Macédoine",
  MG: "Madagascar",
  MY: "Malaisie",
  MW: "Malawi",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MA: "Maroc",
  MQ: "Martinique",
  MU: "Maurice",
  MR: "Mauritanie",
  YT: "Mayotte",
  MX: "Mexique",
  MD: "Moldavie",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NO: "Norvège",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  OM: "Oman",
  UG: "Ouganda",
  UZ: "Ouzbékistan",
  PK: "Pakistan",
  PW: "Palaos",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  NL: "Pays-Bas",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PF: "Polynésie française",
  PR: "Porto Rico",
  PT: "Portugal",
  QA: "Qatar",
  HK: "R.A.S. chinoise de Hong Kong",
  MO: "R.A.S. chinoise de Macao",
  QO: "régions éloignées de l’Océanie",
  CF: "République centrafricaine",
  CD: "République démocratique du Congo",
  DO: "République dominicaine",
  CZ: "République tchèque",
  RE: "Réunion",
  RO: "Roumanie",
  GB: "Royaume-Uni",
  RU: "Russie",
  RW: "Rwanda",
  EH: "Sahara occidental",
  BL: "Saint-Barthélémy",
  KN: "Saint-Kitts-et-Nevis",
  SM: "Saint-Marin",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  SH: "Sainte-Hélène",
  LC: "Sainte-Lucie",
  WS: "Samoa",
  AS: "Samoa américaines",
  ST: "Sao Tomé-et-Principe",
  SN: "Sénégal",
  RS: "Serbie",
  CS: "Serbie-et-Monténégro",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SK: "Slovaquie",
  SI: "Slovénie",
  SO: "Somalie",
  SD: "Soudan",
  LK: "Sri Lanka",
  SE: "Suède",
  CH: "Suisse",
  SR: "Suriname",
  SJ: "Svalbard et Île Jan Mayen",
  SZ: "Swaziland",
  SY: "Syrie",
  TJ: "Tadjikistan",
  TW: "Taïwan",
  TZ: "Tanzanie",
  TD: "Tchad",
  TF: "Terres australes françaises",
  IO: "Territoire britannique de l'océan Indien",
  PS: "Territoire palestinien",
  TH: "Thaïlande",
  TL: "Timor oriental",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TA: "Tristan da Cunha",
  TN: "Tunisie",
  TM: "Turkménistan",
  TR: "Turquie",
  TV: "Tuvalu",
  UA: "Ukraine",
  EU: "Union européenne",
  UY: "Uruguay",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viêt Nam",
  WF: "Wallis-et-Futuna",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
};

export const CitizenShip = {
  AF: "Afghan",
  AX: "Île-Åland",
  AL: "Albanais",
  DZ: "Algérien",
  AS: "Samoan-américain",
  AD: "Andorran",
  AO: "Angolais",
  AI: "Anguillaise",
  AQ: "Antarctique",
  AG: "Antiguais",
  AR: "Argentine",
  AM: "Arménien",
  AW: "Aruban",
  AU: "Australien",
  AT: "Autrichien",
  AZ: "Azerbaïdjanais",
  BS: "Bahamien",
  BH: "Bahreïnien",
  BD: "Bangladais",
  BB: "Barbadien",
  BY: "Biélorusse",
  BE: "Belge",
  BZ: "Bélizien",
  BJ: "Béninois",
  BM: "Bermudien",
  BT: "Bhoutanais",
  BO: "Bolivien",
  BQ: "Bonaire",
  BA: "Bosniaque",
  BW: "Botswanais",
  BV: "Bouvet-île",
  BR: "Brésilien",
  IO: "BIOT",
  BN: "Brunéien",
  BG: "Bulgare",
  BF: "Burkinabé",
  BI: "Burundais",
  CV: "Cape-Verdien",
  KH: "Cambodgien",
  CM: "Camerounais",
  CA: "Canadien",
  KY: "Caïmanais",
  CF: "Centrafricain",
  TD: "Tchadien",
  CL: "Chilien",
  CN: "Chinois",
  CX: "Île-Christmas",
  CC: "Cocos",
  CO: "Colombien",
  KM: "Comorien,",
  CG: "Congolais",
  CD: "Congolais",
  CK: "Cook",
  CR: "Costa-Ricain",
  CI: "Ivoirien",
  HR: "Croate",
  CU: "Cubain",
  CW: "Curaçaoan",
  CY: "Chypriote",
  CZ: "Tchèque",
  DK: "Danois",
  DJ: "Djiboutien",
  DM: "Dominicain",
  DO: "Dominicain",
  EC: "Equatorien",
  EG: "Egyptien",
  SV: "Salvadorien",
  GQ: "Equatoguinéen",
  ER: "Érythréen",
  EE: "Estonien",
  ET: "Éthiopien",
  FK: "Falkland",
  FO: "Féroïen",
  FJ: "Fidjien",
  FI: "Finlandais",
  FR: "Français",
  GF: "Guyanais-français",
  PF: "Français-de-Polynésie",
  TF: "Français-des-Terres-australes",
  GA: "Gabonais",
  GM: "Gambien",
  GE: "Géorgien",
  DE: "Allemand",
  GH: "Ghanéen",
  GI: "Gibraltar",
  GR: "Grec-hellénique",
  GL: "Groenlandais",
  GD: "Grenadien",
  GP: "Guadeloupéen",
  GU: "Guamien-Guambat",
  GT: "Guatémaltèque",
  GG: "Île-de-la-Manche",
  GN: "Guinéen",
  GW: "Bissau-Guinéen",
  GY: "Guyanais",
  HT: "Haïtien",
  HM: "Île-Heard",
  VA: "Vatican",
  HN: "Hondurien",
  HK: "Hongkongais",
  HU: "Hongrois",
  IS: "Islandais",
  IN: "Indien",
  ID: "Indonésien",
  IR: "Iranien",
  IQ: "Irakien",
  IE: "Irlandais",
  IM: "Manx",
  IL: "Israélien",
  IT: "Italien",
  JM: "Jamaïcain",
  JP: "Japonais",
  JE: "Île-anglo-normande",
  JO: "Jordanien",
  KZ: "Kazakhstanais",
  KE: "Kenyan",
  KI: "I-Kiribati",
  KP: "Nord-Coréen",
  KR: "Sud-Coréen",
  KW: "Koweïtien",
  KG: "Kirghiz",
  LA: "Lao-Laotien",
  LV: "Letton",
  LB: "Libanais",
  LS: "Basotho",
  LR: "Libérien",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Lituanien",
  LU: "Luxembourgeois",
  MO: "Chinois",
  MK: "Macédonien",
  MG: "Malgache",
  MW: "Malawien",
  MY: "Malaisien",
  MV: "Maldivien",
  ML: "Malien",
  MT: "Maltais",
  MH: "Marshallais",
  MQ: "Martiniquais",
  MR: "Mauritanien",
  MU: "Mauricien",
  YT: "Mahorais",
  MX: "Mexicain",
  FM: "Micronésien",
  MD: "Moldave",
  MC: "Monacan",
  MN: "Mongol",
  ME: "Monténégrin",
  MS: "Montserratien",
  MA: "marocain",
  MZ: "Mozambicain",
  MM: "Birmane",
  NA: "Namibien",
  NR: "Nauruan",
  NP: "Népalais",
  NL: "Néerlandais",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  NI: "Nicaraguayen",
  NE: "Nigérien",
  NG: "Nigérien",
  NU: "Niuean",
  NF: "Île-Norfolk",
  MP: "Mariannes-du-Nord",
  NO: "Norvégien",
  OM: "Omanais",
  PK: "Pakistanais",
  PW: "Palauan",
  PS: "Palestinien",
  PA: "Panaméen",
  PG: "Papou",
  PY: "Paraguayen",
  PE: "Péruvien",
  PH: "Philippin",
  PN: "Île-Pitcairn",
  PL: "Polonais",
  PT: "Portugais",
  PR: "Porto-Rico",
  QA: "Qatari",
  RE: "Réunionnais",
  RO: "Roumain",
  RU: "Russe",
  RW: "Rwandais",
  BL: "Barthélemy",
  SH: "Saint-Helenian",
  KN: "Nevisien",
  LC: "Sainte-Lucie",
  MF: "Saint-Martinoise",
  PM: "Saint-Pierrais",
  VC: "Vincentien",
  WS: "Samoan",
  SM: "Sammarinais",
  ST: "São-Toméen",
  SA: "Saoudien",
  SN: "Sénégalais",
  RS: "Serbe",
  SC: "Seychellois",
  SL: "Sierra-Léonais",
  SG: "Singapourien",
  SX: "Sint-Maarten",
  SK: "Slovaque",
  SI: "Slovène",
  SB: "Salomon",
  SO: "Somalien",
  ZA: "Sud-Africain",
  GS: "Géorgien-du-Sud",
  SS: "Sud-Soudanais",
  ES: "Espagnol",
  LK: "Sri-Lankais",
  SD: "Soudanais",
  SR: "Surinamais",
  SJ: "Svalbard",
  SZ: "Swazi",
  SE: "Suédois",
  CH: "Suisse",
  SY: "Syrien",
  TW: "Taïwanais",
  TJ: "Tadjikistanais",
  TZ: "Tanzanien",
  TH: "Thaïlandais",
  TL: "Timorais",
  TG: "Togolais",
  TK: "Tokélaouan",
  TO: "Tongien",
  TT: "Trinidadien",
  TN: "Tunisien",
  TR: "Turc",
  TM: "Turkmène",
  TC: "Turcs-et-Caïques",
  TV: "Tuvaluan",
  UG: "Ougandais",
  UA: "Ukrainien",
  AE: "Émirien",
  GB: "Britannique",
  UM: "Américain",
  US: "Américain",
  UY: "Uruguayen",
  UZ: "Ouzbekistanais",
  VU: "Vanuatuan",
  VE: "Vénézuélien",
  VN: "Vietnamien",
  VG: "British-Virgin-Island",
  VI: "Vierge-américaine",
  WF: "Wallisien",
  EH: "Sahraoui",
  YE: "Yéménite",
  ZM: "Zambien",
  ZW: "Zimbabwéen",
};
