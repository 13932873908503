import React, { useState } from "react";
import Files from "react-files";
import { Button, FileItem } from "@/components/common";
import PropTypes from "prop-types";
import { fireAlert } from "@/redux/features/alertsSlice";

// react-files source : https://www.npmjs.com/package/react-files
// a complete guide to file upload: https://betterprogramming.pub/a-complete-guide-of-file-uploading-in-javascript-2c29c61336f5


const DragAndDrop = (
  {
    className,
    horizontal = false,
    isMultiple = true,
    accepts = ["image/png", "image/jpeg", ".pdfs", "audio/*"],
    formatList,
    maxFileSize = 10,
    minFileSize = 0,
    onChange,
  }) => {


  const [uploadedFiles, setUploadedFiles] = useState([]);

  // get files when selected and add them to state for display purpose
  const onFilesChange = (files) => {
    isMultiple ? setUploadedFiles([...uploadedFiles, ...files]) : setUploadedFiles(files);
    onChange(files);
  };

  // error to display when error on file upload
  const onFilesError = (error, file) => {
    dispatch(fireAlert({
        type: "error",
        message: error.message,
      },
    ));
  };

  // remove displayed file from the dom
  const onFileDelete = (index) => {
    setUploadedFiles([
      ...uploadedFiles.slice(0, index),
      ...uploadedFiles.slice(index + 1, uploadedFiles.length),
    ]);
  };
  // submit files
  const handleFileSubmit = () => {
    // use File server reference to submit request
    submitFiles(uploadedFiles);
  };

  return (
    <div className={`${className} my-4`}>
      {uploadedFiles.length === 0 &&
      <Files
        className={`flex ${!horizontal && "flex-col"} items-center justify-around py-6 px-5 bg-gray-50 rounded-md border-2 border-blue-300 border-dashed cursor-pointer`}
        onChange={onFilesChange}
        onError={onFilesError}
        accepts={accepts} // accepts=["image/png", "image/jpeg", ".pdfs", "audio/*"]
        multiple={isMultiple}
        maxFileSize={maxFileSize * 1000000} // maxFileSize={10000000}
        minFileSize={minFileSize * 1000000} // minFileSize={0}
        clickable
      >
        <i className="ut-cloud-upload ut--5x text-gray-300" />
        {horizontal && <hr className="h-[100px] w-[2px] bg-gray-100" />}
        <div className={`${!horizontal && "text-center"} `}>
          <p>Glissez et déposer votre document ici</p>
          <p className="text--caption my-2 text-gray-400">Formats supportés: {formatList}</p>
          <p className="text--caption text-gray-400">Taille maximale: 10MB</p>

          <span className="text--caption my-2 text-gray-400">- OU -</span>

          <Button type="button" buttonStyle="btn--outline--default" className={!horizontal && "mx-auto"}>Sélectionner
            un
            document</Button>
        </div>

      </Files>}

      {uploadedFiles.map((file, i) => (
        <FileItem
          key={i}
          file={file}
          onFileDelete={() => onFileDelete(i)}
        />
      ))}
    </div>
  );
};

DragAndDrop.propTypes = {
  className: PropTypes.string,
  // true active la selection multiple
  isMultiple: PropTypes.bool,
  // les formats supporté au niveau du text "Formats supportés:"
  formatList: PropTypes.string.isRequired,
  // maxFileSize en Megabyte
  maxFileSize: PropTypes.number,
  // minFileSize en Megabyte
  minFileSize: PropTypes.number,
};

export default DragAndDrop;
