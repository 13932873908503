import styled from "@emotion/styled";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import React from "react";


const CustomTooltip = styled(({ className,  ...props }: TooltipProps) => (
  <Tooltip onClick={props.onClick} {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "\"Poppins\", sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    padding: "10px 20px",
    boxShadow: "0px 2px 10px 3px rgba(0, 0, 0, 0.12)",
  },
}));

export default CustomTooltip;