/**
 * I want to create a hook that'll handle for us Get API request without installing SWR/React Query
 * It's an experimentation and will be more flexible. We'll replace useFetch by this
 * *Important: I'll use the book **React - The Road To Enterprise** as foundation
 */
import { useEffect, useState } from "react";

// export type APIState = "idle" | "pending" | "success" | "error"

export type ApiConfig<T> = {
  initialData?: T,
  deps: any[]
}

export type ApiFunction<T = unknown> = (...args: unknown[]) => T | Promise<T>

const useAPI = <TData = unknown, TError = unknown, >(fn: ApiFunction<TData>, config: ApiConfig<TData> = { deps: [] }) => {
  const { initialData, deps } = config;
  const [data, setData] = useState<TData | undefined>(initialData);
  const [error, setError] = useState<TError | unknown>();

  const fetchRequest = async <A>(...args: A[]) => {
    try {
      const data = await fn(...args);
      setData(await data)
      setError(undefined)
    } catch (error) {
      setData(undefined)
      setError(error)
    }
  };

  useEffect(() => {
    fetchRequest();
  }, [...deps]);

  return {
    data,
    error,
  };
};

export default useAPI;