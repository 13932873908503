import React, { useEffect } from "react";
import Lottie from "react-lottie";
import animationCloture from "@/assets/dataAnimationCloture.json";
import "./dotsLoading.scss";
import { useAppSelector } from "@/hooks/reduxToolkitHook";
import { Redirect } from "react-router-dom";

export const MonthlyPayslipPending = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationCloture,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const { paysheetsGenerationJob } = useAppSelector(state => state.paysheets);

  const paysheetGenerationIsDone = paysheetsGenerationJob && paysheetsGenerationJob.status == "done";
  if (paysheetGenerationIsDone) {
    return <Redirect push to="/dashboard" />;
  }

  return (
    <div className="w-full h-screen flex flex-column justify-center align-center bg-blue-50">

      <div className="rounded-lg bg-white m-auto w-1/2 text-center">
        <div className="bg-blue-100 rounded-t-lg w-full pb-6">
          <Lottie options={defaultOptions} height={200} width={400} />
        </div>
        <div className="py-6 text-gray-500">
          <span className="text-blue-500 text-lg font-semibold"> Génération des bulletins en cours <p
            className="one">.</p> <p className="two">.</p> <p className="three">.</p></span>
          <p className="text-xs">Les bulletins de paie du mois sont en cours de génération. <br /> <strong>Cette
            opération peut durer jusqu'à 10min.</strong></p>
          <p className="text-xs mt-3"> Vous aurez accès à votre compte à la fin de l'opération. <br />Vous pouvez aussi
            rafraichir la page dans quelques minutes.</p>
          <p className="text-xs"> Un mail vous sera envoyé à la fin de l'opération.</p>
        </div>
      </div>
    </div>
  );
};
