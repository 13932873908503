import { ErrorMessage } from "@hookform/error-message";
import { ModalForm } from "components/common";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { updateUserData } from "@/redux/features/usersSlice";
import { useAppDispatch } from "@/hooks/reduxToolkitHook";


export type GeneratedDocFormType = {
  girl_name: string | null;
  start_date: Date | string | null;
  father_first_name: string | null;
  father_last_name: string | null;
  mother_first_name: string | null;
  mother_last_name: string | null;
  identity_number?: string;
};

const defaultValues: GeneratedDocFormType = {
  girl_name: "",
  father_first_name: "",
  father_last_name: "",
  mother_first_name: "",
  mother_last_name: "",
  start_date: new Date().toLocaleDateString(),
  identity_number: "",
};

export type GenerateUserDocFormProps = {
  onModalOpen: boolean;
  onModalClose: () => void;
  currentUser?: any;
  lastAction?: (e?: any) => void;
  setOpenPDFNow?: (v: boolean) => void;
  hasWantsDocConfirme?: boolean;
};

const GenerateUserDocForm = ({
                               onModalOpen,
                               onModalClose,
                               currentUser,
                               lastAction = () => {
                               },
                               setOpenPDFNow = (v) => {
                                 v;
                               },
                               hasWantsDocConfirme = false,
                             }: GenerateUserDocFormProps) => {
  const [userWantsDoc, setUserWantsDoc] = useState(false);
  const [, setRequestStatus] = useState("idle");
  const dispatch = useAppDispatch();

  const handleFormSubmit = async (data: GeneratedDocFormType) => {
    if (hasWantsDocConfirme) {
      if (userWantsDoc) {
        await appendAdditionalDataToUser(data);
        setUserWantsDoc(false);
        onModalClose();
        setOpenPDFNow(true);
      } else {
        setUserWantsDoc((oldValue) => !oldValue);
      }
    } else {
      await appendAdditionalDataToUser(data);
      setUserWantsDoc(false);
      onModalClose();
      setOpenPDFNow(true);
    }
  };

  const handleModalClose = () => {
    hasWantsDocConfirme && setUserWantsDoc(false);
    onModalClose();
    lastAction();
  };

  const appendAdditionalDataToUser = async (data: any) => {
    try {
      let params = {
        data: { "parents_names": JSON.stringify(data) },
        id: currentUser.id,
      };

      setRequestStatus("pending");
      const res = await dispatch(updateUserData(params)).unwrap();
      setRequestStatus("fulfilled");
    } catch (e) {
      setRequestStatus("failed");
    }
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "all",
  });

  const Form = () => {
    return (
      <form className="px-6">
        {currentUser?.sex === "F" && (
          <div className="mb-4">
            <label className="text--submenu--noActif mb-1">
              Nom de jeune fille *
            </label>
            <input
              {...register("girl_name", {
                required: "Ce champ est requis",
              })}
              className="form--custom w-full mt-2"
              type="text"
            />
            <ErrorMessage
              className="text--error"
              errors={errors}
              name="girl_name"
              as="p"
            />
          </div>
        )}

        <div className="flex space-x-4 mb-7">
          <div className="">
            <label className="text--submenu--noActif mb-1">
              Nom du Père *
            </label>
            <input
              {...register("father_last_name", {
                required: "Ce champ est requis",
              })}
              className="form--custom w-full mt-2"
              type="text"
            />
            <ErrorMessage
              className="text--error"
              errors={errors}
              name="father_name"
              as="p"
            />
          </div>
          <div className="">
            <label className="text--submenu--noActif mb-1">
              Prénoms du Père *
            </label>
            <input
              {...register("father_first_name", {
                required: "Ce champ est requis",
              })}
              className="form--custom w-full mt-2"
              type="text"
            />
            <ErrorMessage
              className="text--error"
              errors={errors}
              name="father_first_name"
              as="p"
            />
          </div>
        </div>

        <div className="flex space-x-4 mb-7">
          <div className="">
            <label className="text--submenu--noActif mb-1">
              Nom de la mère *
            </label>
            <input
              {...register("mother_last_name", {
                required: "Ce champ est requis",
              })}
              className="form--custom w-full mt-2"
              type="text"
            />
            <ErrorMessage
              className="text--error"
              errors={errors}
              name="mother_name"
              as="p"
            />
          </div>
          <div className="">
            <label className="text--submenu--noActif mb-1">
              Prénoms de la mère *
            </label>
            <input
              {...register("mother_first_name", {
                required: "Ce champ est requis",
              })}
              className="form--custom w-full mt-2"
              type="text"
            />
            <ErrorMessage
              className="text--error"
              errors={errors}
              name="mother_first_name"
              as="p"
            />
          </div>
        </div>
      </form>
    );
  };

  return (
    hasWantsDocConfirme &&
      <ModalForm
        open={onModalOpen}
        onModalClose={handleModalClose}
        modalTitle={`Ajout terminé`}
        cancelButtonLabel={userWantsDoc ? "Annuler" : "Fermer"}
        submitButtonLabel={userWantsDoc ? "Enregistrer" : "ok"}
        modalWidth={"sm"}
        formSubmit={handleModalClose}>
        {!userWantsDoc && (
          <div className="flex flex-col justify-center items-center">

            <svg width="162" height="162" viewBox="0 0 162 162" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="81" cy="81" r="81" fill="#F7F9FA" />
              <path
                d="M128.071 55.0412L64.7355 118.376L35.7069 89.3478L43.1488 81.9059L64.7355 103.44L120.629 47.5994L128.071 55.0412Z"
                fill="#399AB3" />
            </svg>


            <span className="text-lg text-blue-500 font-semibold mt-2">🎉🎊 Félicitations 🎊🎉</span>
            <p className="mt-4 text-center">
              Vous avez terminé l'enregistrement du collaborateur ! <br />
              Rendez vous sur votre espace e-CNPS pour déclarer votre collaborateur.
            </p>
          </div>
        )
        }
      </ModalForm>
      // :
      // <ModalForm
      //   open={onModalOpen}
      //   onModalClose={handleModalClose}
      //   modalTitle={`Générer la fiche d'embauche.`}
      //   cancelButtonLabel={"Annuler"}
      //   submitButtonLabel={"Enregistrer"}
      //   modalWidth={"sm"}
      //   formSubmit={handleSubmit(handleFormSubmit)}>
      //   <Form />
      // </ModalForm>
  );
};
export default GenerateUserDocForm;
