import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "@/pages/dashboard/Dashboard";
import {
    AbsenceRoutes,
    AccountingRouters,
    BonusRoutes,
    BulletinsRoutes,
    Cnps,
    CollaboDetailsRoute,
    CollaboFolders,
    CollaboRoutes,
    CollaboTeamRoutes,
    CompanyFolders,
    CompanyTrash,
    FeatNotAvailable,
    PayrollTaxes,
    Taxes,
    SalaryPaymentsRouters,
    SettingsRoutes,
    Summary,
} from "@/constants/lazies_components";
import Sidebar from "comp_common/sidebar/Sidebar";
import { CircularProgress } from "@mui/material";
import Reports from "@/pages/dashboard/reports/Reports";

const RhRoutes = () => {
  return (
      <main className="flex w-full">
          <Sidebar/>
          <div className="pages flex-[4] overflow-y-scroll bg-gray-100">
              <Suspense
                  fallback={<div className="flex items-center justify-center h-screen"><CircularProgress/></div>}>
                  <Switch>
                      <Route exact path={["/", "/dashboard"]} component={Dashboard}/>
                      <Route path="/ajout-collaborateur" component={CollaboRoutes}/>
                      <Route path="/collaborateurs/:id" component={CollaboDetailsRoute}/>
                      <Route path="/element-de-remuneration" component={BonusRoutes}/>
                      <Route path="/conges-et-absences" component={AbsenceRoutes}/>
                      <Route path="/bulletin-de-salaire" component={BulletinsRoutes}/>
                      <Route path="/comptabilite" component={AccountingRouters} />
                      <Route path="/paiement-des-salaires" component={SalaryPaymentsRouters} />
                      <Route path="/parametres" component={SettingsRoutes} />
                      <Route path="/gestion-du-personnel" component={CollaboTeamRoutes}/>
                      <Route path="/rapports" component={Reports} />

                      <Route path="/declaration-et-organisme/synthese" component={Summary}/>
                      <Route path="/declaration-et-organisme/cnps" component={Cnps}/>
                      <Route path="/declaration-et-organisme/impots-sur-salaire" component={PayrollTaxes}/>
                      <Route path="/declaration-et-organisme/taxes" component={Taxes}/>
                      {/*<Route path="/declaration-et-organisme/aib" component={AIB}/>*/}
                      <Route path="/documents/documents-collaborateurs/:id?" component={CollaboFolders}/>
                      <Route path="/documents/:id?" component={CompanyFolders}/>
                      <Route path="/corbeille/:id?" component={CompanyTrash}/>

                      {/*<Route path="/validations/absences" component={Absence} />*/}
                      {/*<Route path="/validations/note-de-frais" component={NoteDeFrais} />*/}
                      {/*<Route path="/validations/acompte-sur-salaire" component={AcompteSalary} />*/}
                      {/*<Route path="/validations/absences/detail" component={AbsenceDetail} />*/}

                      {/*<Route path="/temps-et-activite/heure-suplementaire" component={HeureSup} />*/}
                      <Route path="/link" component={FeatNotAvailable}/>

                      <Redirect to="/"/>
            </Switch>
          </Suspense>
        </div>
      </main>
  );
};
export default RhRoutes;
