import { http } from "@/utils/http";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_FETCH_DELETE_REGULATIONS_URL,
  ADD_FETCH_USER_REGULATIONS_URL,
  CANCEL_REGULATIONS_URL,
  GET_PATCH_DELETE_REGULATIONS_URL,
} from "@/constants/endpoints";
import { AxiosResponse } from "@/types/api/AxiosType";
import {
  BulkUpdateDeleteEntryType,
  UserRegulationsFormType,
  UserRegulationsType,
  UserUpdateRegulationsFormType,
} from "@/types/api/UserRegulationsType";
import { getUserDeductions, getUserIndemnity } from "@/requests/api";


// fetch all deductions
export const fetchDeductions = createAsyncThunk("get-deductions", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL)
    .then(res => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data });
    });
});

// fetch all indemnities
export const fetchIndemnities = createAsyncThunk("get-indemnities", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL)
    .then(res => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data });
    });
});

// fetch all available deductions for specific user
export const fetchAllUserDeductions = createAsyncThunk(
  "get-users/:id/deductions", async (args: { id: string, params?: { month?: string, type: string } }, thunkApi) => {
    return getUserDeductions(args.id, args.params)
      .then(data => data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });

// fetch all available indemnities for specific user
export const fetchAllUserIndemnities = createAsyncThunk(
  "get-users/:id/indemnities", async (args: { id: string, params?: { month?: string, type: string } }, thunkApi) => {
    return getUserIndemnity(args.id, args.params)
      .then(data => data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });


// fetch indemnities for specific user for a specific month
export const fetchUserIndemnities = createAsyncThunk(
  "get-users/:id/next-indemnities", async (args: { id: string, params?: { month: string, type: string } }, thunkApi) => {
    return getUserIndemnity(args.id, args.params)
      .then(data => data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });

// fetch deductions for specific user for a specific month
export const fetchUserDeductions = createAsyncThunk(
  "get-users/:id/next-deductions", async (args: { id: string, params?: { month: string, type: string } }, thunkApi) => {
    return getUserDeductions(args.id, args.params)
      .then(data => data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });

export const bulkCancelAsyncDeduction = createAsyncThunk<AxiosResponse<BulkUpdateDeleteEntryType[]>, BulkUpdateDeleteEntryType>(
  "deduction/bulkDeleteAsyncDeduction",
  async (deduction: Partial<BulkUpdateDeleteEntryType>, thunkApi) => {
    return http
      .request({
        method: "patch",
        url: CANCEL_REGULATIONS_URL,
        data: deduction,
      })
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const createAsyncRegulation = createAsyncThunk<AxiosResponse<UserRegulationsType[]>, UserRegulationsFormType>(
  "regulation/CreateAsyncRegulation",
  async (regulation: Partial<UserRegulationsFormType>, thunkApi) => {
    return http
      .post<AxiosResponse<UserRegulationsType[]>>(ADD_FETCH_DELETE_REGULATIONS_URL, regulation)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const updateAsyncRegulation = createAsyncThunk<AxiosResponse<UserRegulationsType>, {
  id?: string,
  data?: Partial<UserUpdateRegulationsFormType | FormData>,
}>(
  "regulation/updateAsyncRegulation",
  async ({ id, data }: {
    id?: string,
    data?: Partial<UserUpdateRegulationsFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<AxiosResponse<UserRegulationsType>>(GET_PATCH_DELETE_REGULATIONS_URL.replace(":id", `${id}`), data)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const bulkCancelAsyncIndemnity = createAsyncThunk<AxiosResponse<BulkUpdateDeleteEntryType[]>, BulkUpdateDeleteEntryType>(
  "indemnity/bulkDeleteAsyncIndemnity",
  async (indemnity: Partial<BulkUpdateDeleteEntryType>, thunkApi) => {
    return http
      .patch<AxiosResponse<BulkUpdateDeleteEntryType[]>>(CANCEL_REGULATIONS_URL, indemnity)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const createAsyncDeduction = createAsyncThunk<AxiosResponse<UserRegulationsType>, UserRegulationsFormType>(
  "deduction/createAsyncDeduction",
  async (deduction: Partial<UserRegulationsFormType>, thunkApi) => {
    return http
      .post<AxiosResponse<UserRegulationsType>>(ADD_FETCH_DELETE_REGULATIONS_URL, deduction)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const createAsyncIndemnity = createAsyncThunk<AxiosResponse<UserRegulationsType>, UserRegulationsFormType>(
  "indemnity/createAsyncIndemnity",
  async (indemnity: Partial<UserRegulationsFormType>, thunkApi) => {
    return http
      .post<AxiosResponse<UserRegulationsType>>(ADD_FETCH_USER_REGULATIONS_URL.replace(":id", `${indemnity.user}`), indemnity)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


// initial state
const initialState: {
  regulations: UserRegulationsType[],
  deductionsStatus: string,
  deductionsErrors?: any,

  userAllDeductions: UserRegulationsType[],
  userAllDeductionsStatus: string,
  userAllDeductionsErrors?: any,

  userDeductions: UserRegulationsType[],
  userDeductionsStatus: string,
  userDeductionsErrors?: any,

  addDeductionStatus: string,
  addDeductionErrors?: any,

  deleteDeductionErrors?: any,
  deleteDeductionStatus: string,

  //modify later
  indemnitiesStatus: string,
  indemnitiesErrors?: any,

  userAllIndemnities: UserRegulationsType[],
  userAllIndemnitiesStatus: string,
  userAllIndemnitiesErrors?: any,

  userIndemnities: UserRegulationsType[],
  userIndeemnitiesStatus: string,
  userIndemnitiesError?: any,

  addIndemnityStatus: string,
  addIndemnityErrors?: any,

  deleteIndemnityErrors?: any,
  deleteIndemnityStatus: string,

} = {
  regulations: [],
  userAllDeductions: [],
  userAllDeductionsStatus: "",

  userDeductions: [],
  userDeductionsStatus: "idle",

  deductionsStatus: "idle",
  addDeductionStatus: "idle",
  deleteDeductionStatus: "idle",

  userAllIndemnities: [],
  userAllIndemnitiesStatus: "",

  userIndemnities: [],
  userIndeemnitiesStatus: "idle",

  indemnitiesStatus: "idle",
  addIndemnityStatus: "idle",
  deleteIndemnityStatus: "idle",
};

const regulationsSlice = createSlice({
  name: "regulations",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all deduction
    builder
      .addCase(fetchDeductions.pending, (state) => {
        state.deductionsStatus = "loading";
      })
      .addCase(fetchDeductions.fulfilled, (state, action) => {
        state.deductionsStatus = "succeeded";
        state.regulations = action.payload.data;
      })
      .addCase(fetchDeductions.rejected, (state, action) => {
        state.deductionsStatus = "failed";
        state.deductionsErrors = action.error;
      });

    builder
      .addCase(fetchUserDeductions.pending, (state) => {
        state.userDeductionsStatus = "loading";
      })
      .addCase(fetchUserDeductions.fulfilled, (state, action) => {
        state.userDeductionsStatus = "succeeded";
        state.userDeductions = action.payload;
      })
      .addCase(fetchUserDeductions.rejected, (state, action) => {
        state.userDeductionsStatus = "failed";
        state.userDeductionsErrors = action.error;
      });

    // user all deductions fetching
    builder
      .addCase(fetchAllUserDeductions.pending, (state) => {
        state.userAllDeductionsStatus = "loading";
      })
      .addCase(fetchAllUserDeductions.fulfilled, (state, action) => {
        state.userAllDeductionsStatus = "succeeded";
        state.userAllDeductions = action.payload;
      })
      .addCase(fetchAllUserDeductions.rejected, (state, action) => {
        state.userAllDeductionsStatus = "failed";
        state.userAllDeductionsErrors = action.error;
      });


    builder
      .addCase(createAsyncDeduction.pending, (state) => {
        state.addDeductionStatus = "loading";
      })
      .addCase(createAsyncDeduction.fulfilled, (state, action) => {
        state.addDeductionStatus = "succeeded";
        state.regulations = [...state.regulations, action.payload.data];

      })
      .addCase(createAsyncDeduction.rejected, (state, action) => {
        state.addDeductionStatus = "failed";
        state.addDeductionErrors = action.error;
      });
    // add regulation
    builder
      .addCase(createAsyncRegulation.pending, (state) => {
        state.addDeductionStatus = "loading";
      })
      .addCase(createAsyncRegulation.fulfilled, (state, action) => {
        state.addDeductionStatus = "succeeded";
        state.regulations = [...state.regulations, ...action.payload.data];

      })
      .addCase(createAsyncRegulation.rejected, (state, action) => {
        state.addDeductionStatus = "failed";
        state.addDeductionErrors = action.error;
      });


    // delete bulk deduction
    builder
      .addCase(bulkCancelAsyncDeduction.pending, (state) => {
        state.deleteDeductionStatus = "loading";
      })
      .addCase(bulkCancelAsyncDeduction.fulfilled, (state) => {
        state.deleteDeductionStatus = "succeeded";
      })
      .addCase(bulkCancelAsyncDeduction.rejected, (state, action) => {
        state.deleteDeductionStatus = "failed";
        state.deleteDeductionErrors = action.error;
      });


    // get  all indemnities
    builder
      .addCase(fetchIndemnities.pending, (state) => {
        state.indemnitiesStatus = "loading";
      })
      .addCase(fetchIndemnities.fulfilled, (state, action) => {
        state.indemnitiesStatus = "succeeded";
        state.regulations = action.payload.data;
      })
      .addCase(fetchIndemnities.rejected, (state, action) => {
        state.indemnitiesStatus = "failed";
        state.indemnitiesErrors = action.error;
      });

    builder
      .addCase(fetchUserIndemnities.pending, (state) => {
        state.userIndeemnitiesStatus = "loading";
      })
      .addCase(fetchUserIndemnities.fulfilled, (state, action) => {
        state.userIndeemnitiesStatus = "succeeded";
        state.userIndemnities = action.payload;
      })
      .addCase(fetchUserIndemnities.rejected, (state, action) => {
        state.userIndeemnitiesStatus = "failed";
        state.userIndemnitiesError = action.error;
      });

    // user all indemnities fetching
    builder
      .addCase(fetchAllUserIndemnities.pending, (state) => {
        state.userAllIndemnitiesStatus = "loading";
      })
      .addCase(fetchAllUserIndemnities.fulfilled, (state, action) => {
        state.userAllIndemnitiesStatus = "succeeded";
        state.userAllIndemnities = action.payload;
      })
      .addCase(fetchAllUserIndemnities.rejected, (state, action) => {
        state.userAllIndemnitiesStatus = "failed";
        state.userAllIndemnitiesErrors = action.error;
      });


    builder
      .addCase(createAsyncIndemnity.pending, (state) => {
        state.addIndemnityStatus = "loading";
      })
      .addCase(createAsyncIndemnity.fulfilled, (state, action) => {
        state.addIndemnityStatus = "succeeded";
        state.regulations = [...state.regulations, action.payload.data];

      })
      .addCase(createAsyncIndemnity.rejected, (state, action) => {
        state.addIndemnityStatus = "failed";
        state.addIndemnityErrors = action.error;
      });

    // delete bulk indemnity
    builder
      .addCase(bulkCancelAsyncIndemnity.pending, (state) => {
        state.deleteIndemnityStatus = "loading";
      })
      .addCase(bulkCancelAsyncIndemnity.fulfilled, (state) => {
        state.deleteIndemnityStatus = "succeeded";
      })
      .addCase(bulkCancelAsyncIndemnity.rejected, (state, action) => {
        state.deleteIndemnityStatus = "failed";
        state.deleteIndemnityErrors = action.error;
      });


  },
});

export default regulationsSlice.reducer;

