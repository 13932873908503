import React, { ChangeEventHandler, forwardRef, InputHTMLAttributes } from "react";
import "./RadioButton.scss";

type RadioButtonProps = {
  text?: string,
  name?: string,
  value?: string,
  defaultChecked?: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement>,
  className?: string,
  inputProps?: InputHTMLAttributes<HTMLInputElement>,
  disabled?: boolean,
}

function RadioButton({
                       text,
                       name,
                       value,
                       defaultChecked,
                       onChange,
                       className,
                       inputProps,
                       disabled,
                     }: RadioButtonProps) {
  const cName = !className ? "text--caption" : className;
  return (
    <label className="container">
      <p className={`${cName}`}>{text}</p>
      <input
        type="radio"
        disabled={disabled}
        value={value}
        name={name}
        defaultChecked={defaultChecked}
        onChange={onChange}
        {...inputProps}
      />
      <span className="checkmark" />
    </label>
  );
}

export default forwardRef(RadioButton);