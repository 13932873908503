const subscribe = (eventName: any, listener: any) => {
  document.addEventListener(eventName, listener);
};

const unsubscribe = (eventName: any, listener: any) => {
  document.removeEventListener(eventName, listener);
};

const publish = (eventName: any, data?: any) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};
export { publish, subscribe, unsubscribe };