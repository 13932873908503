import absencesServiceData from "./services/absences-service.json";
import advancesServiceData from "./services/advances-service.json";
import awardsServiceData from "./services/awards-service.json";
import documentsServiceData from "./services/documents-service.json";
import identityServiceData from "./services/identity-service.json";
import paysheetsServiceData from "./services/paysheets-service.json";
import regulationsServiceData from "./services/regulations-service.json";
import settingsServiceData from "./services/settings-service.json";
import teamsServiceData from "./services/teams-service.json";

export type EndpointInfo = {
  required_roles: string[];
  resource_uri: string;
  method: string;
}

export type Endpoints = {
  [key: string]: EndpointInfo
}
export type MapperKeysType =
  "absences-service"
  | "advances-service"
  | "awards-service"
  | "documents-service"
  | "identity-service"
  | "paysheets-service"
  | "regulations-service"
  | "settings-service"
  | "teams-service"

const mapper: {
  [key: string]: Endpoints
} = {
  "absences-service": absencesServiceData,
  "advances-service": advancesServiceData,
  "awards-service": awardsServiceData,
  "documents-service": documentsServiceData,
  "identity-service": identityServiceData,
  "paysheets-service": paysheetsServiceData,
  "regulations-service": regulationsServiceData,
  "settings-service": settingsServiceData,
  "teams-service": teamsServiceData,
};

export default mapper;