import "./App.scss";
import React, { useEffect, useState } from "react";
import moment from "moment/min/moment-with-locales";
import { ThemeProvider } from "@mui/material/styles";
import Theme from "./Theme";
import ErrorBoundary from "./ErrorBoundary";
import { AlertBox } from "@/components/common";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import RhRoutes from "@/layouts/RhRoutes";
import Page404 from "@/pages/error-pages/Page404";
import { MonthlyPayslipPending } from "@/pages/monthly-payslip/MonthlyPayslipPending";
import { AppGuard } from "@/layouts/AppGuard";
import { useAppDispatch, useAppSelector } from "@/hooks/reduxToolkitHook";
import { closeAlert } from "@/redux/features/alertsSlice";
import { getUserKcInfos } from "@/utils/keycloakUtils";
import { subscribe } from "@/utils/events";
import { setUserKcInfos } from "@/redux/features/usersSlice";
import Navbar from "comp_common/navbar/Navbar";
import SwitchCompany from "@/pages/auth-pages/SwitchCompany";
import FreshworksButton from "comp_common/fresh-desks/FreshdesksButton";

moment.locale("fr");

const App = () => {
  const [alertObj, setAlertObj] = useState({
    open: false,
    type: "success",
    message: "",
    position: { vertical: "bottom", horizontal: "right" },
  });

  const { alert } = useAppSelector(state => state.alerts);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Fetch authenticated user infos from keycloak
    getUserKcInfos().catch(console.error);
    subscribe("userKcInfosReady", (e: { detail: any; }) => {
      const user = e.detail;
      dispatch(setUserKcInfos(user));
    });
  }, [dispatch]);

  return (
    // un auth manager devrait se charger de rediriger l'utilisateur quand il est
    // authentifié et en fonction de ses droit soit vers l'espace RH soit vers l'espace collabo
    <ThemeProvider theme={Theme}>
      <ErrorBoundary>
        <AlertBox type={alert.type} open={alert.is_fired} onClose={() => dispatch(closeAlert())}
                  message={alert.message} />

        <AlertBox type={alertObj.type} open={alertObj.open}
                  onClose={() => setAlertObj({ ...alertObj, open: false })} message={alertObj.message} />

        <div className="App">
          <div className="overflow-y-hidden relative">
            <Router>
              <Switch>
                {/* Mettez la route /entreprises en premier pour qu'elle soit rendue en premier sans la Navbar */}
                <Route path="/entreprises" component={SwitchCompany} />

                {/* Toutes les autres routes sont enveloppées dans une div avec la Navbar */}
                <Route>

                  <div className="absolute bottom-0 right-0 m-6 mb-4 {/*z-[2147483647]*/}">
                    <FreshworksButton />
                  </div>
                  <Navbar boardingInProgress={false} />
                  <AppGuard>
                    <Switch>
                      <RhRoutes />
                      <Redirect to="/404" />
                    </Switch>
                  </AppGuard>
                  <Route path="/monthly-payslip-pending" component={MonthlyPayslipPending} />
                  <Route path="/404" component={Page404} />

                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
