import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainerProps,
  CartesianGridProps,
  XAxisProps,
  YAxisProps,
  BarProps,
} from "recharts";
import "./Chart.scss";

type YAxisTypesDeduction = {
  number: number;
  category: string;
  undefined: undefined;
};

type bPropsType = {
  fill?: string[];
  radius?: BarProps["radius"];
  barSize?: BarProps["barSize"];
};

export type ChartLabelAndData<
  DataWithoutNameProperty,
  DataType extends YAxisProps["type"]
> = {
  [Property in keyof DataWithoutNameProperty]: YAxisTypesDeduction[`${DataType}`];
} & {
  name: string;
};

/**
 *
 *
 * @export
 * @interface DashboardChartProps
 */
export interface DashboardChartProps<
  DataKey,
  DataType extends YAxisProps["type"]
> {
  /**
   *
   *
   * @type {ResponsiveContainerProps} as JS object
   * @memberof DashboardChartProps
   */
  rcProps?: Omit<ResponsiveContainerProps, "children">;

  /**
   * Some properties of BarcHart
   *
   * @type {Partial<typeof BarChart>}
   * @memberof DashboardChartProps
   */
  bcProps?: Partial<typeof BarChart> /* {
    width?: number;
    height?: number;
    margin?: number;
  }; */;

  /**
   * The CartesianGridProps
   *
   * @type {CartesianGridProps} as JS object
   * @memberof DashboardChartProps
   */
  cgProps?: Partial<CartesianGridProps>;

  /**
   * The X axis props
   *
   * @type {XAxisProps} as JS object
   * @memberof DashboardChartProps
   */
  xaProps?: XAxisProps;

  /**
   * The Y axis props
   *
   * @type {YAxisProps} as JS object
   * @memberof DashboardChartProps
   */
  yaProps?: YAxisProps;

  /**
   *
   *
   * @type {YAxisProps["type"]}
   * @memberof DashboardChartProps
   */
  yaType?: YAxisProps["type"];

  /**
   * Indicate if the chart will have tooltip when hovered
   *
   * @type {boolean}
   * @memberof DashboardChartProps
   */
  hasToolTip?: boolean;

  /**
   *
   *
   * @type {bPropsType}
   * @memberof DashboardChartProps
   */
  bProps?: Partial<bPropsType> & { colors?: string[] };

  /**
   *
   *
   * @type {ChartLabelAndData<DataKey, DataType>[]}
   * @memberof DashboardChartProps
   */
  chartData: ChartLabelAndData<DataKey, DataType>[];
}

export const FillDefaultValue = {
  blue: ["#7BDFF9", "#69BACF", "#5597A8", "#406E7A", "#233C43", "#0C1416"],
  red: ["#FC8982", "#C56C66", "#92514D", "#804A46", "#502F2D", "#231413"],
  yellow: ["#F4F77B", "#CACD67", "#9D9F52", "#777941", "#4D4E2A", "#242414"],
  green: ["#74F333", "#61CA2C", "#4C9A24", "#366D1A", "#214210", "#081004"],
  mix1: ["#6bd8d5","#f340b5","#399AB3","#425ec4","#fff95a"], 
  mix2: ["#399AB3","#6bd8d5","#f340b5","#425ec4","#fff95a"],
};
export const ChartDataDefaultValue = {
  chartData: [
    {
      name: "Thor",
      mc: 25,
      tau: 50,
    },
    {
      name: "Hulk",
      mc: 30,
      tau: 15,
    },
    {
      name: "Strange",
      mc: 10,
      tau: 50,
    },
    {
      name: "SpiderMan",
      mc: 45,
      tau: 20,
    },
  ],

  rcProps: {
    width: "100%",
    height: 400,
  },

  bcProps: {
    width: 500,
    height: 300,
    margin: {
      top: 5,
      right: 10,
      left: 10,
      bottom: 5,
    },
  },

  cgProps: {
    strokeDasharray: "3 3",
    vertical: false,
  },

  xaProps: {
    dataKey: "name",
    axisLine: false,
    tickLine: false,
  },

  yaProps: {
    type: "number",
    axisLine: false,
    tickLine: false,
    tickCount: 10,
    /* domain: [0, 500], */
  },

  hasToolTip: true,

  bProps: {
    fill: FillDefaultValue,
    radius: [4, 4, 4, 4],
    barSize: 4,
  },
};

export default function DashboardChart<
  DataKey,
  DataType extends YAxisProps["type"],
  
>(props: DashboardChartProps<DataKey, DataType>) {
  const chartDataKeys = props.chartData[0] ? Object.keys(props.chartData[0]).filter(
    (key) => key !== "name"
  ) : [];
  const customBarSize = props.chartData[0] ? Object.keys(props.chartData[0]).filter(
    (key) => key == "barSize"
  ) : [];

  // console.log(props.bProps?.colors);

  return (
    <ResponsiveContainer
      {...{ ...props.rcProps, ...ChartDataDefaultValue.rcProps }}>
      <BarChart
        {...{ ...props.bcProps, ...ChartDataDefaultValue.bcProps }}
        /* {...(props.bcProps ?? ChartDataDefaultValue.bcProps)} */
        data={props.chartData}>
        <CartesianGrid
          {...{
            ...props.cgProps,
            ...ChartDataDefaultValue.cgProps,
          }} /* strokeDasharray="3 3" vertical={false} */
          /* {...(props.cgProps ??
            ChartDataDefaultValue.cgProps)} */
        />
        <XAxis
          {...{ ...props.xaProps, ...ChartDataDefaultValue.xaProps }}
          dataKey="name"
        />
        <YAxis
          {...{ ...ChartDataDefaultValue.yaProps, ...props.yaProps }}
          allowDecimals={false}
          /* {...(props.yaProps ?? ChartDataDefaultValue.yaProps)} */
          type={props.yaType}
        />

        {props.hasToolTip && <Tooltip />}

        {chartDataKeys.map((elt, index) => (
          <Bar
            dataKey={elt}
            {...{ ...props.bProps, ...ChartDataDefaultValue.bProps }}
            barSize={props.bProps?.barSize || 6}
            fill={
              props.bProps?.colors !== undefined
                ? props.bProps.colors[index]
                : FillDefaultValue.blue[index]
            }
            key={elt}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
