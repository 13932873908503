const menus = [
  // {
  //   text: "Approbations",
  //   iconNameWithClass: "ut-spe-check-square",
  //   subMenus: [{
  //     link: "/validations/absences",
  //     text: "Absences",
  //   }],
  // },
  {
    text: "Congés et Absences",
    iconNameWithClass: "ut-spe-calendar",
    subMenus: [
      {
        link: "/conges-et-absences/synthesis",
        text: "Synthèse",
      }, {
        link: "/conges-et-absences/conges",
        text: "Congés Payés",
      },
      {
        link: "/conges-et-absences/absences",
        text: "Absences",
      },
      {
        link: "/conges-et-absences/planning",
        text: "Planning",
      },
    ],
  },
  {
    text: "Elements de rémunération",
    iconNameWithClass: "ut-spe-wallet",
    subMenus: [{
      link: "/element-de-remuneration/primes",
      text: "Primes",
    },
      // {
      //   link: "/element-de-remuneration/note-de-frais",
      //   text: "Note de frais",
      // },
      {
        link: "/element-de-remuneration/indemnite-non-soumise",
        text: "Indemnités non soumises",
      },
      {
        link: "/element-de-remuneration/acompte-sur-salaire",
        text: "Acomptes sur salaire",
      },
      {
        link: "/element-de-remuneration/prets",
        text: "Prêts salariés",
      },
      {
        link: "/element-de-remuneration/deductions",
        text: "Déductions",
      },
    ],
  },
  // {
  //   text: "Activités et temps de travail",
  //   iconNameWithClass: "ut-spe-paper",
  //   subMenus: [{
  //     link: "/temps-et-activite/heure-suplementaire",
  //     text: "Heures supplémentaires",
  //   }
  //   ],
  // },
  {
    text: "Bulletins de salaire",
    iconNameWithClass: "ut-spe-paper",
    subMenus: [
      {
        link: "/bulletin-de-salaire",
        text: "Bulletins du mois",
      },
      {
        link: "/bulletin-de-salaire/historique",
        text: "Historique de vos bulletins",
      },
      // {
      //   link: "/cloturer ma paie",
      //   text: "Clôturer ma paie",
      // },
    ],
  },
  {
    text: "Déclarations et Organismes",
    iconNameWithClass: "ut-business",
    subMenus: [
      {
        link: "/declaration-et-organisme/synthese",
        text: "Synthèse",
      }, {
        link: "/declaration-et-organisme/cnps",
        text: "Cotisations  sociales (CNPS, CMU, ...)",
      }, {
        link: "/declaration-et-organisme/impots-sur-salaire",
        text: "Impôts sur salaire",
      },{
        link: "/declaration-et-organisme/taxes",
        text: "Taxes FDFP",
      },
      // {
      //   link: "/declaration-et-organisme/aib",
      //   text: "AIB",
      // },

      // {
      //   link: "/link",
      //   text: "Mutuelle",
      // }, {
      //   link: "/link",
      //   text: "Prévoyance",
      // }, {
      //   link: "/link",
      //   text: "Retraite complémenatire",
      // }
    ],
  },
  {
    text: "Documents",
    iconNameWithClass: "ut-spe-folder",
    subMenus: [
      {
        link: "/documents/",
        text: "Documents entreprises",
      },
      {
        link: "/corbeille/",
        text: "Corbeille",
      },
    ],
  },
  {
    text: "Gestion du personnel",
    iconNameWithClass: "ut-spe-team",
    subMenus: [
      {
        link: "/gestion-du-personnel/equipes",
        text: "Equipes",
      },
      {
        link: "/gestion-du-personnel/annuaire-collaborateurs",
        text: "Annuaire des collaborateurs",
      },
      {
        link: "/gestion-du-personnel/registre-collaborateurs",
        text: "Registre des collaborateurs",
      },


      {
        link: "/gestion-du-personnel/organigramme",
        text: "Organigramme",
      },
    ],
  },
  {
    text: "Paiement des salaires",
    iconNameWithClass: "ut-credit-card",
    subMenus: [
      {
        link: "/paiement-des-salaires/modes-de-paiement",
        text: "Modes de paiement",
      },
      {
        link: "/paiement-des-salaires/etats-de-paiement",
          text: "Etats de paiement des salaires",
      },
        {
            link: "/paiement-des-salaires/virements-bancaires",
            text: "Virements bancaires des salaires",
        },
        {
            link: "/paiement-des-salaires/virements-mobile-money",
            text: "Virements mobile money",
        },
        // {
        //   link: "/paiement-des-salaires/parametres-des-banques",
        //   text: "Paramètre des banques",
        // },
    ],
  },
  {
    text: "Comptabilité",
    iconNameWithClass: "ut-coin-dollar",
    subMenus: [
      {
        link: "/comptabilite/bordereau-de-paie",
        text: "Livre de paie",
      },
      {
        link: "/comptabilite/bordereau-comptable",
        text: "Bordereau comptable",
      },
      // {
      //   link: "/conges-et-absences/provisions",
      //   text: "Provisions de congés payés",
      // },
    ],
  },

  {
    text: "Paramètres",
    iconNameWithClass: "ut-settings",
    subMenus: [{
      link: "/parametres/gestion-access",
      text: "Gestions des accès",
    },
      {
        link: "/parametres/identite-entreprise",
        text: "Identité de l’entreprise",
      },
      // {
      //   link: "/parametres/gestion-collaborateur",
      //   text: "Gestions des collaborateurs",
      // },
    ],
  },
];
//
// if (ENV == "development") {
//   const indexToInsertItemTo = menus.findIndex(object => {
//     return object.iconNameWithClass === "ut-spe-team";
//   });
//   menus.splice(indexToInsertItemTo < 0 ? menus.length : indexToInsertItemTo, 0, {
//     text: "Documents",
//     iconNameWithClass: "ut-spe-folder",
//     subMenus: [
//       {
//         link: "/documents/",
//         text: "Documents entreprises",
//       },
//       {
//         link: "/corbeille/",
//         text: "Corbeille",
//       },
//     ],
//   });
//
// }

export default menus;
