import React, {ForwardedRef, forwardRef} from "react";
import ReactFlagsSelect from "react-flags-select";
import {Countries, CitizenShip} from "./Countries";
import "./DropdownCountries.scss";
import {OnSelect} from "react-flags-select/build/types";

// demo : https://ekwonye-richard.github.io/react-flags-select/?path=/story/reactflagsselect--primary
// source : https://github.com/ekwonye-richard/react-flags-select
// const countryData = Countries;

// const [selected, setSelected] = useState(selectedCountry);

export type DropdownCountrieProps = {
    selectedCountry: any,
    citizenshipType?: boolean,
    className?: string,
    onSelect: OnSelect,
    ref: any
}
const DropdownCountries = forwardRef((
    {selectedCountry, citizenshipType, className, onSelect}: DropdownCountrieProps, _ref:ForwardedRef<HTMLUListElement>
) => (
    <ReactFlagsSelect
        className={`${className} dropdownCountry`}
        searchable
        selected={selectedCountry}
        customLabels={citizenshipType ? CitizenShip : Countries}
        searchPlaceholder="Rechercher un pays"
        onSelect={onSelect}
    />
));

export default DropdownCountries;