import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type targetMonthType = { targetMonth: string | null };

const initialState: targetMonthType = {
  targetMonth: null,
};

const targetMonthSlice = createSlice({
  name: "closing_data",
  initialState,
  reducers: {
    updateTargetMonth: (state: typeof initialState, action: PayloadAction<string | null>) => {
      state.targetMonth = action.payload;
    },
  },
});

export const { updateTargetMonth } = targetMonthSlice.actions;
export default targetMonthSlice.reducer;
