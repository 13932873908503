import { http } from "@/utils/http";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ADD_AWARDS_TO_USER_URL,
  ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL,
  BULK_ADD_AWARDS_TO_USERS_URL,
  FETCH_AWARDS_TYPES_URL,
  FETCH_AWARDS_URL,
  GET_PATCH_DELETE_AWARD_URL,
} from "@/constants/endpoints";
import { AxiosResponse } from "@/types/api/AxiosType";
import {
  AutomatiqueAwardsRulesType,
  AwardsModificationFormType,
  AwardsTypes,
  FetchUpdateDeleteEntryType,
  UserAwardsFormType,
  UserAwardsType,
} from "@/types/api/UserAwardsType";
import { getUserAwards, getUserNextAwards } from "@/requests/api";
import { appendSeniorityAwardToUserAwardsListIfExist } from "@/utils/utils";


export const fetchAwardsTypes = createAsyncThunk("get-awards/awards-types", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<AwardsTypes[]>>(FETCH_AWARDS_TYPES_URL)
    .then(res => res.data)
    .catch(e => {
      // const error = e as AxiosError<CustomError>;
      // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ data: e.data });
    });
});

// fetch all awards
export const fetchAwards = createAsyncThunk("get-awards", async (_, thunkApi) => {
  return http
    .get<AxiosResponse<UserAwardsType[]>>(FETCH_AWARDS_URL)
    .then(res => res.data)
    .catch(e => {
      // const error = e as AxiosError<CustomError>;
      // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
      return thunkApi.rejectWithValue({ data: e.data });
    });
});

// fetch all available awards for specific user
export const fetchAllUserAwards = createAsyncThunk(
  "get-users/:id/awards", async (args: { id: string, seniority: number, seniorityAwardRules: AutomatiqueAwardsRulesType["seniority_award_type_rules"], params?: { month: string } }, thunkApi) => {
    return getUserAwards(args.id, args.params)
      .then(data => appendSeniorityAwardToUserAwardsListIfExist(args.id, args.seniority, args.seniorityAwardRules, data))
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });

// fetch awards for specific user for a specific month
export const fetchUserAwards = createAsyncThunk(
  "get-users/:id/next-awards", async (args: { id: string, seniority: number, seniorityAwardRules: AutomatiqueAwardsRulesType["seniority_award_type_rules"], params?: { month: string } }, thunkApi) => {
    // return getUserNextAwards(args.id, args.seniority, args.seniorityAwardRules, args.params)
    return getUserNextAwards(args.id, args.params)
      .then(data => appendSeniorityAwardToUserAwardsListIfExist(args.id, args.seniority, args.seniorityAwardRules, data))
      .catch(e => {
        // const error = e as AxiosError<CustomError>;
        // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
        return thunkApi.rejectWithValue({ data: e.data });
      });
  });


export const bulkCreateAsyncAward = createAsyncThunk<AxiosResponse<UserAwardsType[]>, UserAwardsFormType>(
  "award/bulkCreateAsyncAward",
  async (award: Partial<UserAwardsFormType>, thunkApi) => {
    return http
      .post<AxiosResponse<UserAwardsType[]>>(BULK_ADD_AWARDS_TO_USERS_URL, award)
      .then(res => res.data)
      .catch(e => {
        // const error = e as AxiosError<CustomError>;
        // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


export const createAsyncAward = createAsyncThunk<AxiosResponse<UserAwardsType>, UserAwardsFormType>(
  "award/createAsyncAward",
  async (award: Partial<UserAwardsFormType>, thunkApi) => {
    return http
      .post<AxiosResponse<UserAwardsType>>(ADD_AWARDS_TO_USER_URL.replace(":id", `${award.user}`), award)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);

export const updateAsyncAward = createAsyncThunk<AxiosResponse<UserAwardsType>, {
  id?: string,
  data?: Partial<AwardsModificationFormType | FormData>,
}>(
  "award/updateAsyncAward",
  async ({ id, data }: {
    id?: string,
    data?: Partial<AwardsModificationFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<AxiosResponse<UserAwardsType>>(GET_PATCH_DELETE_AWARD_URL.replace(":id", `${id}`), data)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);

export const deleteAsyncAward = createAsyncThunk<AxiosResponse<UserAwardsType>, FetchUpdateDeleteEntryType>(
  "award/deleteAsyncAward",
  async ({ pk, id }: FetchUpdateDeleteEntryType, thunkApi) => {
    return http
      .delete<AxiosResponse<UserAwardsType>>(ADD_FETCH_PATCH_DELETE_USER_AWARDS_URL.replace(":id", pk) + id)
      .then(res => res.data)
      .catch(e => {
        // const error = e as AxiosError<CustomError>;
        // const response = error.response as unknown as AxiosResponse<ErrorResponse>;
        return thunkApi.rejectWithValue({ data: e.data });
      });
  },
);


// initial state
const initialState: {
  awardsTypes: AwardsTypes[],
  awardsTypesStatus: string,
  awardsTypesErrors?: any,

  awards: UserAwardsType[],
  awardsStatus: string,
  awardsErrors?: any,

  userAllAwards: UserAwardsType[],
  userAllAwardsStatus: string,
  userAllAwardsErrors?: any,

  userAwards: UserAwardsType[],
  userAwardsStatus: string,
  userAwardsErrors?: any,

  addAwardStatus: string,
  addAwardErrors?: any,

  deleteAwardErrors?: any,
  deleteAwardStatus: string,
} = {
  awardsTypes: [],
  awardsTypesStatus: "idle",
  awards: [],
  userAllAwards: [],
  userAllAwardsStatus: "",

  userAwards: [],
  userAwardsStatus: "idle",

  awardsStatus: "idle",
  addAwardStatus: "idle",
  deleteAwardStatus: "idle",
};

const awardsSlice = createSlice({
  name: "awards",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all awards
    builder
      .addCase(fetchAwards.pending, (state) => {
        state.awardsStatus = "loading";
      })
      .addCase(fetchAwards.fulfilled, (state, action) => {
        state.awardsStatus = "succeeded";
        state.awards = action.payload.data;
      })
      .addCase(fetchAwards.rejected, (state, action) => {
        state.awardsStatus = "failed";
        state.awardsErrors = action.error;
      });

    // user next awards fetching
    builder
      .addCase(fetchUserAwards.pending, (state) => {
        state.userAwardsStatus = "loading";
      })
      .addCase(fetchUserAwards.fulfilled, (state, action) => {
        state.userAwardsStatus = "succeeded";
        state.userAwards = action.payload;
      })
      .addCase(fetchUserAwards.rejected, (state, action) => {
        state.userAwardsStatus = "failed";
        state.userAwardsErrors = action.error;
      });

    // user all awards fetching
    builder
      .addCase(fetchAllUserAwards.pending, (state) => {
        state.userAllAwardsStatus = "loading";
      })
      .addCase(fetchAllUserAwards.fulfilled, (state, action) => {
        state.userAllAwardsStatus = "succeeded";
        state.userAllAwards = action.payload;
      })
      .addCase(fetchAllUserAwards.rejected, (state, action) => {
        state.userAllAwardsStatus = "failed";
        state.userAllAwardsErrors = action.error;
      });

    // awards type fetching
    builder
      .addCase(fetchAwardsTypes.pending, (state) => {
        state.awardsTypesStatus = "loading";
      })
      .addCase(fetchAwardsTypes.fulfilled, (state, action) => {
        state.awardsTypesStatus = "succeeded";
        state.awardsTypes = action.payload.data;

      })
      .addCase(fetchAwardsTypes.rejected, (state, action) => {
        state.awardsTypesStatus = "failed";
        state.awardsTypesErrors = action.error;
      });

    builder
      .addCase(createAsyncAward.pending, (state) => {
        state.addAwardStatus = "loading";
      })
      .addCase(createAsyncAward.fulfilled, (state, action) => {
        state.addAwardStatus = "succeeded";
        state.awards = [...state.awards, action.payload.data];

      })
      .addCase(createAsyncAward.rejected, (state, action) => {
        state.addAwardStatus = "failed";
        state.addAwardErrors = action.error;
      });
    // add award
    builder
      .addCase(bulkCreateAsyncAward.pending, (state) => {
        state.addAwardStatus = "loading";
      })
      .addCase(bulkCreateAsyncAward.fulfilled, (state, action) => {
        state.addAwardStatus = "succeeded";
        state.awards = [...state.awards, ...action.payload.data];

      })
      .addCase(bulkCreateAsyncAward.rejected, (state, action) => {
        state.addAwardStatus = "failed";
        state.addAwardErrors = action.error;
      });

    builder
      .addCase(deleteAsyncAward.pending, (state) => {
        state.deleteAwardStatus = "loading";
      })
      .addCase(deleteAsyncAward.fulfilled, (state, action) => {
        state.awardsStatus = "succeeded";
        state.awards = state.awards.filter((user, _) => user.id !== action.payload.data.id);
      })
      .addCase(deleteAsyncAward.rejected, (state, action) => {
        state.deleteAwardStatus = "failed";
        state.deleteAwardErrors = action.error;
      });

  },
});

// export const {addAsyncAward} = awardsSlice.actions
export default awardsSlice.reducer;

