import React from "react";
import "./TabItem.scss";
import PropTypes from "prop-types";

const TabItem = ({
                   className,
                   children,
                   tabId,
                   value,
                   labelContent, // can be a component
                   isChecked,
                   onChange,
                 }) => {
  return (
    <span className={`${className} contents`}>
      <input type="radio" value={value} className="tabs__radio" name="tab__example" id={tabId}
             onChange={onChange}
             checked={isChecked} />
      <label htmlFor={tabId} className="tabs__label">
        {labelContent}
      </label>
      {children}
    </span>
  );
};

TabItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  tabId: PropTypes.string,
  labelContent: PropTypes.node.isRequired, // can be a component
  value: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default TabItem;
