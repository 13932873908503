import moment from "moment/moment";

export const timeElapsed = (date: any) => {
    // const now = moment();
    // const secondsPast = Math.floor((now - date) / 1000);

    const secondsPast = moment().diff(date,"seconds")
    if (secondsPast < 60) {
        return `${secondsPast} ` + (secondsPast < 1 ? "seconde": "secondes");
    }
    if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `${minutes} `  + (secondsPast < 1 ? "minute": "minutes");
    }
    if (secondsPast < 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `${hours} ` + (secondsPast < 1 ? "heure": "heures");
    }
    if (secondsPast < 2592000) {
        const days = Math.floor(secondsPast / 86400);
        return `${days} ` + (secondsPast < 1 ? "jour": "jours");
    }
    const months = Math.floor(secondsPast / 2592000);
    return `${months} mois`;
}