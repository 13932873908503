import React, { forwardRef } from "react";
import "./MuiDatePicker.scss";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import frLocale from "date-fns/locale/fr";
import { BasePickerProps } from "@mui/x-date-pickers/internals/models/props/basePickerProps";
import { BaseDatePickerProps } from "@mui/x-date-pickers/DatePicker/shared";
import styled from "@emotion/styled";
import { CalendarPickerView } from "@mui/x-date-pickers";

const localeMap = {
  fr: frLocale,
};

const maskMap = {
  fr: "__/__/____",
};

export type MuiDatePickerProps = {
  className?: string;
  selected: BasePickerProps<any, any>["value"];
  disableFuture?: boolean;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
  views?: BaseDatePickerProps<Date>["views"];
  openTo?: CalendarPickerView;
  onYearChange?: (year: any) => void;
  onChange: BasePickerProps<any, any>["onChange"];
  inputFormat?: string;
  disabled?: boolean
};

const CustomTextfield = styled((props: any) => (
        <TextField  {...props} />
    ))`
      & .MuiOutlinedInput-input {
        text-transform: capitalize;
      }

      & .MuiTextField-root > .Mui-disabled {
        border-color: '#e5e5e5';
        background-color: '#f3f4f6',
        color: '#e5e5e5'
      }`
;

const MuiDatePicker = (
    props: MuiDatePickerProps,
    ref: React.Ref<HTMLDivElement> | undefined,
) => {
  const {
    className,
    selected,
    disableFuture,
    minDate,
    maxDate,
    views,
    placeholder,
    openTo = "day",
    disabled,
    onYearChange,
    onChange,
    inputFormat = "dd/MM/yyyy",
  } = props;


  return (
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["fr"]}>
        <DatePicker
            onAccept={onChange}
            ref={ref}
            className={`pr-3 ${className}`}
            mask={maskMap["fr"]}
            inputFormat={inputFormat}
            disabled={disabled}
            value={selected}
            onChange={onChange}
            onYearChange={onYearChange}
            minDate={minDate}
            maxDate={maxDate}
            openTo={openTo}
            views={views}
            disableFuture={disableFuture}
            renderInput={(params) => <CustomTextfield
                {...params}
                inputProps={{
                  ...params.inputProps,
                  classes: {
                    disabled: {
                      borderColor: "#e5e5e5",
                      backgroundColor: "#f3f4f6",
                      color: "#e5e5e5",
                    },
                  },
                  placeholder: placeholder ?? inputFormat,
                }} />}
        />
      </LocalizationProvider>
  );
};

export default forwardRef(MuiDatePicker);
