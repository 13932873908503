export const DEFAULT_ROUTE = "/ajout-collaborateur";
export const PROCESS_ROUTE = `${DEFAULT_ROUTE}/:id/processus`;
export const USER_PROFILE_ROUTE = `${DEFAULT_ROUTE}/:id/profil`;

export const IDENTITY_BASE_ROUTE = "identite";
export const CONTRACT_END_BASE_ROUTE = `${DEFAULT_ROUTE}/:id/fin-de-contrat`;
export const CONTRACT_END_REASON_ROUTE = `${CONTRACT_END_BASE_ROUTE}/motif`;
export const CONTRACT_END_INDEMNITY_ROUTE = `${CONTRACT_END_BASE_ROUTE}/indemnite`;
export const CONTRACT_END_RESUME = `${CONTRACT_END_BASE_ROUTE}/resume`;

export const DEFAULT_IDENTITY_ROUTE = `${DEFAULT_ROUTE}/identite`;
export const IDENTITY_ROUTE = `${DEFAULT_ROUTE}/:id/identite`;
export const PERSONAL_INFOS_ROUTE = `${IDENTITY_ROUTE}/identification`;
export const ADDRESS_INFOS_ROUTE = `${IDENTITY_ROUTE}/contacts`;
export const PUBLIC_INFOS_ROUTE = `${IDENTITY_ROUTE}/autres`;

export const CONTRACT_ROUTE = `${DEFAULT_ROUTE}/:id/contrat`;
export const CONTRACT_TYPE_ROUTE = `${CONTRACT_ROUTE}/type`;
export const WORK_CLASSIFICATION_ROUTE = `${CONTRACT_ROUTE}/classification`;
export const CONTRACT_DURATION_ROUTE = `${CONTRACT_ROUTE}/duree`;

export const WORK_DETAILS_ROUTE = `${DEFAULT_ROUTE}/:id/details-de-travail`;
export const WORK_TIME_ROUTE = `${WORK_DETAILS_ROUTE}/temps`;
export const BASIC_SALARY_ROUTE = `${WORK_DETAILS_ROUTE}/salaire`;

export const WORK_RECOVERY_ROUTE = `${DEFAULT_ROUTE}/:id/reprise-historique`;
export const BALANCE_ROUTE = `${WORK_RECOVERY_ROUTE}/solde`;
export const BASIC_REMUNERATION_ROUTE = `${WORK_RECOVERY_ROUTE}/remuneration`;
