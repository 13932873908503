import * as React from "react";
import isWeekend from "date-fns/isWeekend";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  StaticDatePicker,
  StaticDatePickerProps,
} from "@mui/x-date-pickers/StaticDatePicker";
import frLocale from "date-fns/locale/fr";
import { ParseableDate } from "@mui/x-date-pickers/internals/models/parseableDate";


const localeMap = {
  fr: frLocale,
};

interface StaticDatePickerLandscapeProps {
  value: ParseableDate<Date>;
  onChange: StaticDatePickerProps["onChange"];
  className?: StaticDatePickerProps["className"];
  isWeekend?: typeof isWeekend
}

export default function StaticDatePickerLandscape({
  value,
  onChange,
  isWeekend = () => false,
}: StaticDatePickerLandscapeProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["fr"]}>
      <StaticDatePicker<Date>
        orientation="landscape"
        openTo="day"
        value={value}
        shouldDisableDate={isWeekend}
        onChange={onChange}
        showToolbar={false}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
